import React,{useState,useEffect,useRef} from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BannerService } from '../service/BannerService';
import { Dialog } from "primereact/dialog";
import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import configData from "../config.json";
export const BannerTable = () => {
    let emptyBanner = {
        name: "",
        images: null,
        description:"",
        text:"",
        bannertype:""
    };
    const [banner, setBanner] = useState(null);
    const [deleteBannerDialog, setDeleteBannerDialog] = useState(false);
    const [relistBannerDialog, setRelistBannerDialog] = useState(false);
    const [bannerDetails, setBannerDetails] = useState(emptyBanner);
    const toast = useRef(null);
    const [edit,setEditUpdate] = useState(false)
    const [images, setImage] = useState("")
    const [bannerUpdateDialog, setBannerUpdateDialog] = useState(false);
    const [banid,setBanid] = useState("")
    useEffect(() => {
        const bannerService = new BannerService();
        const config = {
            method: 'get',
            url: configData.SERVER_URL+'admin/banners',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        bannerService.getBanners(config).then(data => setBanner(data));
    }, []);
    useEffect(() => {
        const bannerService = new BannerService();
        const config = {
            method: 'get',
            url: configData.SERVER_URL+'admin/banners',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        bannerService.getBanners(config).then(data => setBanner(data));
    }, [deleteBannerDialog,relistBannerDialog,edit,bannerDetails]);


    const activestatusBodyTemplate = (rowData) => {
        if (rowData.status == "Active") {
            return (
                <>
                    <span className={`product-badge status-instock`}>{rowData.status}</span>
                </>
            );
        } else {
            return (
                <>
                    <span className={`product-badge status-outofstock`}>Not Active</span>
                </>
            );
        }
    };
    const hideDeleteBannerDialog = () => {
        setDeleteBannerDialog(false);
    };
    const hideRelistBannerDialog = () => {
        setRelistBannerDialog(false);
    };
    const deleteBanner = () => {
        const bannerService = new BannerService();
        const config = {
            method: "put",
            url: configData.SERVER_URL+"admin/banners/"+banid,
            headers: {
                "Content-Type": "application/json",
            },
            data: { status: "Active" },
        };
        bannerService.deleteBanner(config).then((data) => {
            if (data) {
                toast.current.show({ severity: "success", summary: "Success", detail: "Banner unlisted successfully", life: 3000 });
                setDeleteBannerDialog(false);
                setBannerDetails(emptyBanner);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error while unlisting banner", life: 3000 });
            }
        });
    };

    const relistBanner = () => {
        const bannerService = new BannerService();
        const config = {
            method: "put",
            url: configData.SERVER_URL+"admin/banners/"+banid,
            headers: {
                "Content-Type": "application/json",
            },
            data: { status: "Not Active" },
        };
        bannerService.relistBanner(config).then((data) => {
            if (data) {
                toast.current.show({ severity: "success", summary: "Success", detail: "Banner relisted successfully", life: 3000 });
                setRelistBannerDialog(false);
                setBannerDetails(emptyBanner);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error while relisting banner", life: 3000 });
            }
        });
    };


    const updateBanner = () => {
        const bannerervice = new BannerService();
        const banner = {
            "name": bannerDetails.name,
            "images": images,
            "description":bannerDetails.description,
            "text":bannerDetails.text,
            "bannertype":bannerDetails.bannerType
        }
        if (edit === true){
            const config = {
                method: 'put',
                url: configData.SERVER_URL+"admin/banners/update/"+banid,
                headers: {
                    'Content-Type' : 'application/json'
                },
                data: banner
            }
            bannerervice.updateBanner(config).then(data => {if (data.status === "success") {
                toast.current.show({severity:'success', summary: 'Success', detail:'Banner updated successfully', life: 3000});
                setBannerUpdateDialog(false);
                setBannerDetails(emptyBanner);
                setEditUpdate(false);
                setBanid("");
            }else{
                toast.current.show({severity:'error', summary: 'Error', detail:'Error while updating banner', life: 3000});
            }});
            return
        }
    };


    const bannerUpdateDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={()=>{setBannerUpdateDialog(false)}} />
            <Button label="Save" icon="pi pi-check" className="p-button-text"  onClick={updateBanner}/>
        </>
    );

    const deleteBannerDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteBannerDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteBanner} />
        </>
    );
    const relistBannerDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideRelistBannerDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={relistBanner} />
        </>
    );

    const editBanner = (banner) => {
        setBannerDetails({ ...banner });
        setEditUpdate(true)
        setBanid(banner._id)
        setImage(banner.images)
        setBannerUpdateDialog(true);
    };
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _banner = { ...bannerDetails };
        _banner[`${name}`] = val;
        setBannerDetails(_banner)
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editBanner(rowData)} />
                {rowData.status =="Active" && <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => {setBannerDetails(rowData);setBanid(rowData["_id"]);setDeleteBannerDialog(true);}} />}
                {rowData.status =="Not Active" && <Button icon="pi pi-list" className="p-button-rounded p-button-info" onClick={() => {setBannerDetails(rowData);setBanid(rowData["_id"]);setRelistBannerDialog(true);}} />}

            </div>
        );
    };
    const [selectedStatF, setSelectedStatF] = useState(null);
    const dt = useRef(null);
    const statItemTemplate = (option) => {
        return <span style={{fontSize:"14px",fontWeight:600,textTransform:'capitalize'}}>{option}</span>;
    }
    const onStatChange = (e) => {
        dt.current.filter(e.value, 'status', 'equals');
        setSelectedStatF(e.value);
    }
    const statFilter = <Dropdown value={selectedStatF} options={["Active","Not Active"]} onChange={onStatChange} itemTemplate={statItemTemplate} placeholder="Select a Status" option showClear style={{width:"110px",fontSize:'10px'}}/>;


    return (
        <>
        <Toast ref={toast} />
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Banners</h5>
                    <DataTable ref={dt} value={banner} className="datatable-responsive" rows={5} paginator>
                        <Column header="Image" body={(data) => <img src={data.images} alt={data.image} width="50" />} />
                        <Column field="name" header="Name" sortable style={{fontSize:"12px"}}/>
                        <Column field="description" header="Description" sortable style={{fontSize:"12px"}}/>
                        <Column field="text" header="Banner text" sortable style={{fontSize:"12px"}}/>
                        <Column field="bannertype" header="Banner type" style={{fontSize:"12px"}}/>
                        <Column field="status" header="Status" body={activestatusBodyTemplate} filter filterElement={statFilter}/>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
        </div>

        <Dialog visible={bannerUpdateDialog} style={{ width: "450px" }} header="Banner Details" modal className="p-fluid" footer={bannerUpdateDialogFooter} onHide={()=>{setBannerUpdateDialog(false)}}>
                            {bannerDetails.images && <img src={bannerDetails.images} alt={bannerDetails.images} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                            <div className="field">
                                <label htmlFor="name">Name</label>
                                <InputText id="name" value={bannerDetails.name} onChange={(e) => onInputChange(e, "name")} required autoFocus />
                            </div>
                            <div className="field">
                                <label htmlFor="name">Description</label>
                                <InputText id="name" value={bannerDetails.description} onChange={(e) => onInputChange(e, "description")} required autoFocus />
                            </div>
                            <div className="field">
                                <label htmlFor="name">Banner Text</label>
                                <InputText id="name" value={bannerDetails.text} onChange={(e) => onInputChange(e, "text")} required autoFocus />
                            </div>

                        </Dialog>
            <Dialog visible={deleteBannerDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteBannerDialogFooter} onHide={hideDeleteBannerDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {bannerDetails && (
                        <span>
                            Are you sure you want to unlist <b>{bannerDetails.name}</b> ?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={relistBannerDialog} style={{ width: "450px" }} header="Confirm" modal footer={relistBannerDialogFooter} onHide={hideRelistBannerDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {bannerDetails && (
                        <span>
                            Are you sure you want to relist banner <b>{bannerDetails.name}</b> ?
                        </span>
                    )}
                </div>
            </Dialog>
        </>
    );
};
