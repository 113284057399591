import React from "react";

export const AppFooter = (props) => {
  return (
    <div className="layout-footer">
      {/* <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logoMBE.png' : 'assets/layout/images/logoMBE.png'} alt="Logo" height="20" className="mr-2" /> */}
      <span className="font-medium ml-2">
        <p>© {new Date().getFullYear()} MBE</p>
      </span>
    </div>
  );
};
