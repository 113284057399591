import React, { useState, useEffect, useRef } from "react";
import S3 from "react-aws-s3";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import "./customButton.css";
import { Toast } from "primereact/toast";
import { CategoryService } from "../service/CategoryService";
import { BrandService } from "../service/BrandsService";
import configData from "../config.json";
import { CONFIG } from "../utils/constants";
export const AddBrandsFormLayout = () => {
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const toast = useRef(null);
  const [image, setImage] = useState("");
  const [category, setCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const fileInput = useRef();

  useEffect(() => {
    const categoryService = new CategoryService();
    const config = {
      method: "get",
      url: configData.SERVER_URL + "admin/getCategories",
      headers: {
        "Content-Type": "application/json",
      },
    };
    categoryService.getCategories(config).then((data) => setCategory(data));
  }, []);
  const onCategoryChange = (e) => {
    setSelectedCategory(e.value);
  };
  const onTemplateUpload = (e) => {
    onUpload(e.files);
  };
  const onUpload = (event) => {
    let file = fileInput.current.files[0];
    let newFileName = fileInput.current.files[0].name.replace(/\..+$/, "");

    const config = CONFIG;
    const ReactS3Client = new S3(config);
    ReactS3Client.uploadFile(file, newFileName).then((data) => {
      if (data.status === 204) {
        setImage(data.location);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Image uploaded successfully",
          life: 3000,
        });
      } else {
        console.log("fail");
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while upload image",
          life: 3000,
        });
      }
    });
  };
  const onSubmit = () => {
    const brandService = new BrandService();
    const brand = {
      name: name,
      decription: desc,
      images: image,
      category: selectedCategory["name"],
    };
    const config = {
      method: "post",
      url: configData.SERVER_URL + "admin/brands",
      headers: {
        "Content-Type": "application/json",
      },
      data: brand,
    };
    brandService.addBrand(config).then((data) => {
      if (data.status === "success") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Brand added successfully",
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while adding a new brand",
          life: 3000,
        });
      }
    });
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12">
          <div className="card">
            <h5>Add Brand</h5>
            <div className="p-fluid formgrid grid">
              <div className="field col-12">
                <label htmlFor="brandName">Brand name</label>
                <InputText
                  id="brandName"
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="field col-12">
                <label htmlFor="brandDesc">Brand desc</label>
                <InputText
                  id="brandDesc"
                  type="text"
                  onChange={(e) => setDesc(e.target.value)}
                />
              </div>
              <div className="field col-12">
                <label htmlFor="brandCategory">Brand category</label>
                <Dropdown
                  value={selectedCategory}
                  options={category}
                  onChange={onCategoryChange}
                  optionLabel="name"
                  placeholder="Select a Category"
                />
              </div>
              <div className="field col-12">
                <label htmlFor="brandImage">Brand image</label>
                <FileUpload
                  ref={fileInput}
                  name="demo[]"
                  customUpload={true}
                  uploadHandler={onTemplateUpload}
                  onUpload={onTemplateUpload}
                  accept="image/*"
                  maxFileSize={1000000}
                  emptyTemplate={
                    <p className="p-m-3" style={{ fontSize: "15px" }}>
                      Drag and drop files to here to upload.
                    </p>
                  }
                />
              </div>
              <div className="field col-12">
                <Button
                  className="p-button-rounded formButton"
                  onClick={onSubmit}
                >
                  Add brand
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
