import React,{useState,useEffect,useRef} from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CategoryService } from '../service/CategoryService';
import { Toast } from 'primereact/toast';
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import configData from "../config.json";
export const CategoryTable = () => {
    let emptyCategory = {
        name: "",
        images: null,
    };
    const [category, setCategory] = useState(null);
    const toast = useRef(null);
    const [deleteCategoryDialog, setDeleteCategoryDialog] = useState(false);
    const [relistCategoryDialog, setRelistCategoryDialog] = useState(false);
    const [categoryDetails, setCategoryDetails] = useState(emptyCategory);
    const [edit,setEditUpdate] = useState(false)
    const [images, setImage] = useState("")
    const [cid,setCid] = useState("")

    useEffect(() => {
        const categoryService = new CategoryService();
        const config = {
            method: 'get',
            url: configData.SERVER_URL+'admin/getCategories',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        categoryService.getCategories(config).then(data => setCategory(data));
    }, []);

    useEffect(() => {
        const categoryService = new CategoryService();
        const config = {
            method: 'get',
            url: configData.SERVER_URL+'admin/getCategories',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        categoryService.getCategories(config).then(data => setCategory(data));
    }, [deleteCategoryDialog,relistCategoryDialog,edit,categoryDetails]);
    const activestatusBodyTemplate = (rowData) => {
        if (rowData.status == "Active") {
            return (
                <>
                    <span className={`product-badge status-instock`}>{rowData.status}</span>
                </>
            );
        } else {
            return (
                <>
                    <span className={`product-badge status-outofstock`}>Not Active</span>
                </>
            );
        }
    };

    const hideDeleteCategoryDialog = () => {
        setDeleteCategoryDialog(false);
    };
    const hideRelistCategoryDialog = () => {
        setRelistCategoryDialog(false);
    };
    const deleteCategory = () => {
        const categoryService = new CategoryService();
        const config = {
            method: "put",
            url: configData.SERVER_URL+"admin/category/"+cid,
            headers: {
                "Content-Type": "application/json",
            },
            data: { status: "Active" },
        };
        categoryService.deleteCategory(config).then((data) => {
            if (data) {
                toast.current.show({ severity: "success", summary: "Success", detail: "Category unlisted successfully", life: 3000 });
                setDeleteCategoryDialog(false);
                setCategoryDetails(emptyCategory);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error while unlisting category", life: 3000 });
            }
        });
    };

    const relistCategory = () => {
        const categoryService = new CategoryService();
        const config = {
            method: "put",
            url: configData.SERVER_URL+"admin/category/"+cid,
            headers: {
                "Content-Type": "application/json",
            },
            data: { status: "Not Active" },
        };
        categoryService.relistCategory(config).then((data) => {
            if (data) {
                toast.current.show({ severity: "success", summary: "Success", detail: "Category relisted successfully", life: 3000 });
                setRelistCategoryDialog(false);
                setCategoryDetails(emptyCategory);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error while relisting category", life: 3000 });
            }
        });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _category = { ...categoryDetails };
        _category[`${name}`] = val;

        setCategoryDetails(_category);
    };


    const [categoryUpdateDialog, setCategoryUpdateDialog] = useState(false);
    const updateCategory = () => {
        const categoryService = new CategoryService();
        const body = {
            "name":categoryDetails.name,
            "images":images
        }
        if (edit === true){
            const config = {
                method: 'put',
                url: configData.SERVER_URL+'admin/category/update/'+cid,
                headers: {
                    'Content-Type' : 'application/json'
                },
                data: body
            }
            categoryService.updateCategory(config).then(data => {if (data.status === "success") {
                toast.current.show({severity:'success', summary: 'Success', detail:'Category updated successfully', life: 3000});
                setCategoryUpdateDialog(false);
                setCategoryDetails(emptyCategory);
                setEditUpdate(false);
                setCid("");
            }else{
                toast.current.show({severity:'error', summary: 'Error', detail:'Error while updating category', life: 3000});
            }});
            return
        }
    };

    const categoryUpdateDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={()=>{setCategoryUpdateDialog(false)}} />
            <Button label="Save" icon="pi pi-check" className="p-button-text"  onClick={updateCategory}/>
        </>
    );

    const deleteCategoryDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCategoryDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteCategory} />
        </>
    );
    const relistCategoryDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideRelistCategoryDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={relistCategory} />
        </>
    );

    const editCategory = (category) => {
        setCategoryDetails({ ...category });
        setEditUpdate(true)
        setCid(category._id)
        setImage(category.images)
        setCategoryUpdateDialog(true);
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editCategory(rowData)} />
                {rowData.status =="Active" && <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => {setCategoryDetails(rowData);setCid(rowData["_id"]);setDeleteCategoryDialog(true);}} />}
                {rowData.status =="Not Active" && <Button icon="pi pi-list" className="p-button-rounded p-button-info" onClick={() => {setCategoryDetails(rowData);setCid(rowData["_id"]);setRelistCategoryDialog(true);}} />}

            </div>
        );
    };

    const [selectedStatF, setSelectedStatF] = useState(null);
    const dt = useRef(null);
    const statItemTemplate = (option) => {
        return <span style={{fontSize:"14px",fontWeight:600,textTransform:'capitalize'}}>{option}</span>;
    }
    const onStatChange = (e) => {
        dt.current.filter(e.value, 'status', 'equals');
        setSelectedStatF(e.value);
    }
    const statFilter = <Dropdown value={selectedStatF} options={["Active","Not Active"]} onChange={onStatChange} itemTemplate={statItemTemplate} placeholder="Select a Status" option showClear style={{width:"110px",fontSize:'10px'}}/>;
    return (
        <>
        <Toast ref={toast} />
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Categories</h5>
                    <DataTable ref={dt} value={category} className="datatable-responsive" rows={5} paginator>
                        <Column header="Image" body={(data) => <img src={data.images} alt={data} width="50" />} />
                        <Column field="name" header="Name" sortable style={{ fontSize: "12px" }} />
                        <Column field="status" header="Status" body={activestatusBodyTemplate} filter filterElement={statFilter}/>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
        <Dialog visible={categoryUpdateDialog} style={{ width: "450px" }} header="Category Details" modal className="p-fluid" footer={categoryUpdateDialogFooter} onHide={()=>{setCategoryUpdateDialog(false)}}>
                            {categoryDetails.images && <img src={categoryDetails.images} alt={categoryDetails.images} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                            <div className="field">
                                <label htmlFor="name">Name</label>
                                <InputText id="name" value={categoryDetails.name} onChange={(e) => onInputChange(e, "name")} required autoFocus />
                            </div>
                        </Dialog>
            <Dialog visible={deleteCategoryDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteCategoryDialogFooter} onHide={hideDeleteCategoryDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {categoryDetails && (
                        <span>
                            Are you sure you want to unlist category <b>{categoryDetails.name}</b> ?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={relistCategoryDialog} style={{ width: "450px" }} header="Confirm" modal footer={relistCategoryDialogFooter} onHide={hideRelistCategoryDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {categoryDetails && (
                        <span>
                            Are you sure you want to relist category <b>{categoryDetails.name}</b> ?
                        </span>
                    )}
                </div>
            </Dialog>
        </>
    );
};
