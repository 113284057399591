import React from "react";
export const DashBoardTopSellingProducts = (props) => {
    return (
        <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
            <div>
                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">{props.title}</span>
                <div className="mt-1 text-600">{props.subtitle}</div>
            </div>
            <div className="mt-2 md:mt-0 flex align-items-center">
                <div className="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style={{ height: "8px" }}>
                    <div className={`bg-${props.color}-500 h-full`} style={{ width: `${props.percent}%` }} />
                </div>
                <span className={`text-${props.color}-500 ml-3 font-medium`}>%{props.percent}</span>
            </div>
        </li>
    );
};
