import * as React from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useState, useRef } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Toast } from "primereact/toast";
import LoadingOverlay from "react-loading-overlay";
import "./customButton.css";
import configData from "../config.json";

export const LoginPageComponent = () => {
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [isActive, setActive] = useState(false);
    const toast = useRef(null);
    const onLogin = (e) => {
        e.preventDefault();
        setActive(true);
        let user = {
            email: email,
            password: pass,
        };
        var config = {
            method: "post",
            url: configData.SERVER_URL+"admin/login",
            headers: {
                "Content-Type": "application/json",
            },
            data: user,
        };
        axios(config)
            .then((response) => {
                console.log('response---->', response);
                if (response.data.token) {
                    sessionStorage.setItem("token", response.data["token"]);
                    sessionStorage.setItem("role", "Admin");
                    window.location.href = "https://storage.googleapis.com/mbewebsite/index.html/#/dashboard";
                    window.location.reload();
                    setActive(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setActive(false);
                toast.current.show({
                    severity: "error",
                    summary: "Error",
                    detail: "Invalid credentials",
                    life: 3000,
                });
            });
    };
    return (
        <>
        <LoadingOverlay active={isActive} spinner>
              
            <Toast ref={toast} />{" "}
            <Card className="p-shadow-7 formcard">
                <h2 className="logintitle"> Log In </h2>{" "}
                <form>
                    <div className="p-fluid">
                        <div className="p-grid mt30">
                            <label htmlFor="email" className="p-col-12 formLabel ">
                                Email{" "}
                            </label>{" "}
                            <div className="p-col-12 mt15">
                                <InputText
                                    id="email"
                                    type="text"
                                    placeholder="Email"
                                    className="formInput"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                />{" "}
                            </div>{" "}
                        </div>{" "}
                        <div className="p-grid mt30">
                            <label htmlFor="password" className="p-col-12 formLabel">
                                Password{" "}
                            </label>{" "}
                            <div className="p-col-12 mt15">
                                <InputText
                                    id="password"
                                    type="password"
                                    placeholder="Password"
                                    className="formInput"
                                    onChange={(e) => {
                                        setPass(e.target.value);
                                    }}
                                />{" "}
                            </div>{" "}
                        </div>{" "}
                    </div>{" "}
                    <div className="p-grid mt30">
                        <div className="p-col-12">
                            <Button className="p-button-rounded formButton" onClick={onLogin}>
                                {" "}
                                Login{" "}
                            </Button>{" "}
                            <br/>
                        </div>{" "}
                    </div>{" "}
                </form>{" "}
                <br />
            </Card>{" "}
            <div>
                <div className="copyRightText"> {/* <p>© {new Date().getFullYear()} MBE</p> */} </div>{" "}
            </div>
            </LoadingOverlay>{" "}
        </>
    );
};
