import React, { useState, useEffect, useRef } from 'react';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProductService } from '../service/ProductService';
import { BrandService } from '../service/BrandsService';
import { DashBoardCards} from './Card';
import { DashBoardTopBrandsCards} from './TopBrandsCard';
import {lineData} from "../Data/graphsData";
import {DashBoardTopSellingProducts} from "./TopSellingProductList";
import configData from "../config.json";

export const Dashboard = () => {

    const [products, setProducts] = useState(null);
    const [brands, setBrands] = useState(null);
    const [order,setOrders]=useState(0)
    const [revenue,setRevenue]=useState(0)
    const [tproducts,setTProducts]=useState(0)
    const [users,setUsers]=useState(0)
    useEffect(() => {
        const productService = new ProductService();
        const brandsService = new BrandService();
        const config = {
            method: 'get',
            url: configData.SERVER_URL+'admin/admin/brands',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        brandsService.getBrands(config).then(data => setBrands(data));
        const config22 = {
            method: "get",
            url: configData.SERVER_URL+"admin/item",
            headers: {
                "Content-Type": "application/json",
            },
        };
        productService.getProduct(config22).then((data) => {console.log(data);setProducts(data)});

        const config3 = {
            method: "get",
            url: configData.SERVER_URL+"admin/dashboard",
            headers: {
                "Content-Type": "application/json",
            },
        };
        productService.getProduct(config3).then((data) => {setOrders(data.orders);setRevenue(data.revenue);setTProducts(data.products);setUsers(data.users)});
    }, []);

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    return (
        <div className="grid">
            <div className="col-12 lg:col-6 xl:col-3">
                <DashBoardCards name={"Orders"} numb={order} numb2={"6"} sectext={" since last login"} iconcolor={"blue"} icon={"pi-shopping-cart"}/>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <DashBoardCards name={"Revenue"} numb={revenue} numb2={"%52+"} sectext={" since last month"} iconcolor={"orange"} icon={"pi-map-marker"}/>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <DashBoardCards name={"Customers"} numb={users} numb2={"3"} sectext={" newly registered"} iconcolor={"cyan"} icon={"pi-users"}/>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <DashBoardCards name={"Products"} numb={tproducts} numb2={"%85+"} sectext={" since last month"} iconcolor={"green"} icon={"pi-money-bill"}/>
            </div>

            <div className="col-12 xl:col-6">
                <div className="card">
                    <h5>Recent Sales</h5>
                    <DataTable value={products} className="p-datatable-customers" rows={5} paginator>
                        {/* <Column header="Image" body={(data) => <img src={data.images} alt={data.image} width="50" />}/> */}
                        <Column field="name" header="Name" sortable/>
                        <Column field="price" header="Price" sortable body={(data) => formatCurrency(data.price)}/>
                        <Column field="category" header="Product Category" sortable />
                        {/* <Column header="View" body={() => (
                            <>
                                <Button icon="pi pi-search" type="button" className="p-button-text"/>
                            </>
                        )}/> */}
                    </DataTable>
                </div>
                <div className="card">
                    <div className="flex justify-content-between align-items-center mb-5">
                        <h5>Top Selling Products</h5>
                    </div>
                    <ul className="list-none p-0 m-0">
                    <DashBoardTopSellingProducts title={"IPhone"} subtitle={"Mobile"} percent={"50"} color={"orange"}/>
                    <DashBoardTopSellingProducts title={"LED TV"} subtitle={"Television"} percent={"16"} color={"cyan"}/>
                    <DashBoardTopSellingProducts title={"Sony Bluetooth Speaker"} subtitle={"Speakers"} percent={"67"} color={"pink"}/>
                    <DashBoardTopSellingProducts title={"Samsung S21 Ultra"} subtitle={"Mobile"} percent={"35"} color={"green"}/>
                    <DashBoardTopSellingProducts title={"Air Pods"} subtitle={"Headsets"} percent={"75"} color={"purple"}/>
                    <DashBoardTopSellingProducts title={"Wireless Routers"} subtitle={"Networking"} percent={"40"} color={"teal"}/>
                    </ul>
                </div>
            </div>

            <div className="col-12 xl:col-6">
                <div className="card">
                    <h5>Sales Overview</h5>
                    <Chart type="line" data={lineData} />
                </div>
                         <DashBoardTopBrandsCards brands={brands} />

            </div>
        </div>
    );
}
