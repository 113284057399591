import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { OrderService } from "../service/OrderService";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { ProgressBar } from "primereact/progressbar";
import configData from "../config.json";
import moment from "moment";
export const OrdersTable = () => {
  const toast = useRef(null);
  const [orderDetails, setOrderDetails] = useState([]);
  const [currentOrderDetails, setCurrentOrderDetails] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [cancelValue, setCancelValue] = useState("");
  const [shippingIDValue, setShippingIDValue] = useState("");
  const menu = useRef(null);
  const items = [
    {
      label: "Update Order Status",
      icon: "pi pi-refresh",
      command: () => {
        setStatusModal(true);
      },
    },
  ];
  const statuses = [
    { name: "Delivered" },
    { name: "Cancelled" },
    { name: "Shipped" },
  ];
  const paymentNotSuccess = [
    { name: "Change order type to COD" },
    { name: "Cancel order" },
  ];
  useEffect(() => {
    const orderService = new OrderService();
    const config = {
      method: "get",
      url: configData.SERVER_URL + "admin/orders?limit=20&offset=0",
      headers: {
        "Content-Type": "application/json",
      },
    };
    orderService.getOrders(config).then((data) => {
      setOrderDetails(data.data);
    });
  }, []);

  const updateOrder = () => {
    const orderService = new OrderService();
    const STATUS_MAP = {
      Cancelled: "CANCELLED",
      Delivered: "DELIVERED",
      ShipmentCreated: "SHIPMENT_CREATED",
      Shipped: "ON_WAY",
    };
    let body = {
      orderStatus: STATUS_MAP[selectedStatus.name],
      orderStatusLog: {
        status: STATUS_MAP[selectedStatus.name],
        time: new Date(),
        shippingId: shippingIDValue || null,
      },
    };
    const config = {
      method: "put",
      url: configData.SERVER_URL + "home/order/" + currentOrderDetails["_id"],
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
    };
    orderService.updateOrders(config).then((data) => {
      if (data.success) {
        setOrderDetails([]);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Order Status Updated Successfully",
          life: 3000,
        });
        const config = {
          method: "get",
          url: configData.SERVER_URL + "admin/orders?limit=20&offset=0",
          headers: {
            "Content-Type": "application/json",
          },
        };
        orderService.getOrders(config).then((data) => {
          setOrderDetails(data.data);
          setStatusModal(false);
        });
        setStatusModal(false);
      }
    });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        {rowData.orderStatus !== "Cancelled" &&
          rowData.orderStatus !== "Delivered" && (
            <div className="actions">
              <Menu
                model={items}
                popup
                ref={menu}
                id="popup_menu"
                style={{ fontSize: "11px" }}
              />
              <img
                src="https://img.icons8.com/fluency/27/000000/menu-2.png"
                onClick={(event) => {
                  menu["current"].toggle(event);
                  setCurrentOrderDetails(rowData);
                }}
              />
            </div>
          )}
      </>
    );
  };
  const [selectedStatusF, setSelectedStatusF] = useState(null);
  const dt = useRef(null);
  const statusesF = ["Delivered", "Shipped", "Ordered", "Cancelled", "Pending"];
  const statusItemTemplate = (option) => {
    return (
      <span
        style={{
          fontSize: "14px",
          fontWeight: 600,
          textTransform: "capitalize",
        }}
      >
        {option}
      </span>
    );
  };
  const onStatusChange = (e) => {
    dt.current.filter(e.value, "orderStatus", "equals");
    setSelectedStatusF(e.value);
  };
  const statusFilter = (
    <Dropdown
      value={selectedStatusF}
      options={statusesF}
      onChange={onStatusChange}
      itemTemplate={statusItemTemplate}
      placeholder="Select a Status"
      showClear
      style={{ width: "110px", fontSize: "10px" }}
    />
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedDate, setSelectedDate] = useState([
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    new Date(),
  ]);

  const formatDate = (date) => {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = "0" + month;
    }

    if (day < 10) {
      day = "0" + day;
    }

    return date.getFullYear() + "-" + month + "-" + day;
  };
  const [fs, setFs] = useState("");
  const codeFilter = (value, filter) => {
    var date1 = new Date(value);
    var date2 = new Date(filter.split(":")[0]);
    var date3 = new Date(filter.split(":")[1]);

    return (
      date1.getTime() > date2.getTime() && date1.getTime() < date3.getTime()
    );
  };
  const onDateChangeUseeffect = (e) => {
    let dat2 = formatDate(e[0]);
    let dat1 = formatDate(e[1]);
    dt.current.filter(dat2 + ":" + dat1, "orderplaced.at", "custom");
    setSelectedDate(e);
  };
  const onDateChange = (e) => {
    if (e.value[1] !== null) {
      let dat2 = formatDate(e.value[1]);
      dt.current.filter(fs + ":" + dat2, "orderplaced.at", "custom");
      setSelectedDate(e.value);
      return;
    }
    if (e.value[0] !== null && e.value[1] === null) {
      let dat2 = formatDate(e.value[0]);
      setFs(dat2);
      setSelectedDate(e.value);
      return;
    }
  };
  const reset = () => {
    setSelectedStatusF(null);
    setSelectedDate(undefined);
    setGlobalFilter("");
    dt.current.reset();
  };
  const header = (
    <div className="table-header">
      <Button
        type="button"
        label="Clear"
        className="p-button-outlined"
        icon="pi pi-filter-slash"
        onClick={reset}
      />
      <span className="p-input-icon-left" style={{ float: "right" }}>
        <i className="pi pi-search" />
        <InputText
          type="search"
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search"
          style={{ marginRight: "15px" }}
        />
        <Calendar
          readOnlyInput
          value={selectedDate}
          onChange={onDateChange}
          dateFormat="yy-mm-dd"
          showIcon
          selectionMode="range"
          className="p-column-filter"
          placeholder="Select Date"
          style={{
            marginRight: "5px",
            width: "257px",
            marginBottom: "5px",
            marginTop: "5px",
            marginLeft: "auto",
            fontSize: "12px !important",
          }}
        />
      </span>
    </div>
  );
  const dateFilter = (
    <Calendar
      readOnlyInput
      value={selectedDate}
      onChange={onDateChange}
      dateFormat="yy-mm-dd"
      selectionMode="range"
      className="p-column-filter"
      placeholder="Select Date"
      style={{ display: "none" }}
    />
  );

  useEffect(() => {
    if (orderDetails.length === 0) {
      if (dt !== null && dt.current != null) {
        onDateChangeUseeffect([
          new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          new Date(),
        ]);
      }
    }
  }, []);
  return (
    <>
      <Toast ref={toast} />
      {orderDetails && orderDetails.length === 0 && (
        <h1 style={{ fontSize: "16px" }}>Orders</h1>
      )}
      {orderDetails && orderDetails.length > 0 && (
        <div className="grid crud-demo">
          <div className="col-12">
            <div className="card">
              <DataTable
                ref={dt}
                value={orderDetails}
                globalFilter={globalFilter}
                header={header}
                dataKey="_id"
                className="datatable-responsive"
                rows={5}
                paginator
              >
                <Column
                  field="_id"
                  header="Order ID"
                  style={{ fontSize: "12px" }}
                  body={(rowData) => (
                    <>
                      <span style={{ fontSize: "12px" }}>
                        {rowData && rowData._id && rowData.orderId}
                      </span>
                    </>
                  )}
                />
                <Column
                  field="orderStatus"
                  header="Order Status"
                  filter
                  filterElement={statusFilter}
                  style={{ fontSize: "12px" }}
                />
                <Column
                  field="auditInfo.createdTime"
                  header="Time Created"
                  filter
                  filterElement={dateFilter}
                  filterFunction={codeFilter}
                  style={{ fontSize: "12px" }}
                  body={(rowData) => (
                    <>
                      <span style={{ fontSize: "12px" }}>
                        {moment(rowData?.auditInfo?.createdTime).format(
                          "DD-MM-YYYY"
                        )}
                      </span>
                    </>
                  )}
                />
                <Column
                  field="totalWithTax"
                  header="Order Amount"
                  sortable
                  style={{ fontSize: "12px" }}
                />
                <Column
                  field="deliveryDate"
                  header="Date"
                  sortable
                  style={{ fontSize: "12px" }}
                  body={(rowData) => (
                    <>
                      <span style={{ fontSize: "12px" }}>
                        {rowData &&
                        rowData.orderdelivered &&
                        rowData.orderdelivered.at
                          ? rowData.orderdelivered.at.split("T")[0]
                          : "Not Delivered"}
                      </span>
                    </>
                  )}
                />
                <Column
                  field="orderShipped.at"
                  header="Shipping Status"
                  style={{ fontSize: "12px" }}
                  body={(rowData) => (
                    <>
                      <span style={{ fontSize: "12px" }}>
                        {rowData &&
                        rowData.ordershipped &&
                        rowData.ordershipped.at
                          ? rowData.ordershipped.at.split("T")[0]
                          : "Not Shipped"}
                      </span>
                    </>
                  )}
                />
                <Column
                  header="View"
                  style={{ fontSize: "12px" }}
                  body={(rowData) => (
                    <>
                      <Button
                        icon="pi pi-search"
                        type="button"
                        className="p-button-text"
                        onClick={() => {
                          setCurrentOrderDetails(rowData);
                          setOpenModal(true);
                        }}
                      />
                    </>
                  )}
                />
                <Column
                  body={actionBodyTemplate}
                  style={{ fontSize: "12px" }}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      )}
      {orderDetails && orderDetails.length === 0 && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: "6px" }}
        ></ProgressBar>
      )}
      <Dialog
        header="Order Details"
        visible={openModal}
        style={{ width: "50vw" }}
        onHide={() => setOpenModal(false)}
        modal
      >
        <h1 style={{ fontSize: "14px", color: "black" }}>User Details</h1>
        <Divider />
        {currentOrderDetails &&
          currentOrderDetails["userDetails"] &&
          currentOrderDetails["userDetails"].name && (
            <h1 style={{ fontSize: "12px", color: "#898080" }}>
              Name:{" "}
              <span style={{ fontSize: "12px", color: "#bcb7b7" }}>
                {currentOrderDetails["userDetails"].name}
              </span>
            </h1>
          )}
        {currentOrderDetails &&
          currentOrderDetails["userDetails"] &&
          currentOrderDetails["userDetails"].email && (
            <h1 style={{ fontSize: "12px", color: "#898080" }}>
              Email:{" "}
              <span style={{ fontSize: "12px", color: "#bcb7b7" }}>
                {currentOrderDetails["userDetails"].email}
              </span>
            </h1>
          )}
        {currentOrderDetails &&
          currentOrderDetails["userDetails"] &&
          currentOrderDetails["userDetails"].gender && (
            <h1 style={{ fontSize: "12px", color: "#898080" }}>
              Gender:{" "}
              <span style={{ fontSize: "12px", color: "#bcb7b7" }}>
                {currentOrderDetails["userDetails"].gender}
              </span>
            </h1>
          )}
        {currentOrderDetails &&
          currentOrderDetails["userDetails"] &&
          currentOrderDetails["userDetails"].dob && (
            <h1 style={{ fontSize: "12px", color: "#898080" }}>
              Date of birth:{" "}
              <span style={{ fontSize: "12px", color: "#bcb7b7" }}>
                {currentOrderDetails["userDetails"].dob}
              </span>
            </h1>
          )}
        <Divider />
        <h1 style={{ fontSize: "14px", color: "black" }}>Shipping Address</h1>
        <Divider />
        {currentOrderDetails &&
          currentOrderDetails["shippingAddress"] &&
          currentOrderDetails["shippingAddress"].addressLine1 && (
            <h1 style={{ fontSize: "12px", color: "#898080" }}>
              address line 1:{" "}
              <span style={{ fontSize: "12px", color: "#bcb7b7" }}>
                {currentOrderDetails["shippingAddress"].addressLine1}
              </span>
            </h1>
          )}
        {currentOrderDetails &&
          currentOrderDetails["shippingAddress"] &&
          currentOrderDetails["shippingAddress"].addressLine2 && (
            <h1 style={{ fontSize: "12px", color: "#898080" }}>
              address line 2:{" "}
              <span style={{ fontSize: "12px", color: "#bcb7b7" }}>
                {currentOrderDetails["shippingAddress"].addressLine2}
              </span>
            </h1>
          )}
        {currentOrderDetails &&
          currentOrderDetails["shippingAddress"] &&
          currentOrderDetails["shippingAddress"].city && (
            <h1 style={{ fontSize: "12px", color: "#898080" }}>
              city:{" "}
              <span style={{ fontSize: "12px", color: "#bcb7b7" }}>
                {currentOrderDetails["shippingAddress"].city}
              </span>
            </h1>
          )}
        {currentOrderDetails &&
          currentOrderDetails["shippingAddress"] &&
          currentOrderDetails["shippingAddress"].state && (
            <h1 style={{ fontSize: "12px", color: "#898080" }}>
              state:{" "}
              <span style={{ fontSize: "12px", color: "#bcb7b7" }}>
                {currentOrderDetails["shippingAddress"].state}
              </span>
            </h1>
          )}
        {currentOrderDetails &&
          currentOrderDetails["shippingAddress"] &&
          currentOrderDetails["shippingAddress"].country && (
            <h1 style={{ fontSize: "12px", color: "#898080" }}>
              country:{" "}
              <span style={{ fontSize: "12px", color: "#bcb7b7" }}>
                {currentOrderDetails["shippingAddress"].country}
              </span>
            </h1>
          )}
        {currentOrderDetails &&
          currentOrderDetails["shippingAddress"] &&
          currentOrderDetails["shippingAddress"].postalCode && (
            <h1 style={{ fontSize: "12px", color: "#898080" }}>
              pin code:{" "}
              <span style={{ fontSize: "12px", color: "#bcb7b7" }}>
                {currentOrderDetails["shippingAddress"].postalCode}
              </span>
            </h1>
          )}
        <Divider />
        <h1 style={{ fontSize: "14px", color: "black" }}>Payment Details</h1>
        <Divider />
        {currentOrderDetails && currentOrderDetails["paymentStatus"] && (
          <h1 style={{ fontSize: "12px", color: "#898080" }}>
            Payment Status:{" "}
            <span style={{ fontSize: "12px", color: "#bcb7b7" }}>
              {currentOrderDetails["paymentStatus"]}
            </span>
          </h1>
        )}
        {currentOrderDetails && currentOrderDetails["paymentType"] && (
          <h1 style={{ fontSize: "12px", color: "#898080" }}>
            Payment Method:{" "}
            <span style={{ fontSize: "12px", color: "#bcb7b7" }}>
              {currentOrderDetails["paymentType"]}
            </span>
          </h1>
        )}
        {currentOrderDetails &&
          currentOrderDetails["paymentsDetails"] &&
          currentOrderDetails["paymentsDetails"].totalCharges && (
            <h1 style={{ fontSize: "12px", color: "#898080" }}>
              Total Amount:{" "}
              <span style={{ fontSize: "12px", color: "#bcb7b7" }}>
                {currentOrderDetails["paymentsDetails"].totalCharges}
              </span>
            </h1>
          )}
        {currentOrderDetails &&
          currentOrderDetails["paymentsDetails"] &&
          currentOrderDetails["paymentsDetails"].currency && (
            <h1 style={{ fontSize: "12px", color: "#898080" }}>
              Currency:{" "}
              <span style={{ fontSize: "12px", color: "#bcb7b7" }}>
                {currentOrderDetails["paymentsDetails"].currency}
              </span>
            </h1>
          )}
        <Divider />
        <h1 style={{ fontSize: "14px", color: "black" }}>Purchased Items</h1>
        <Divider />
        {currentOrderDetails &&
          currentOrderDetails["ItemsOrdered"] &&
          currentOrderDetails["ItemsOrdered"]["items"] && (
            <DataTable value={currentOrderDetails["ItemsOrdered"]["items"]}>
              <Column
                field="itemName"
                header="Name"
                sortable
                style={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="itemPrice"
                header="Price"
                style={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="itemCategory"
                header="Category"
                style={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="itemBrand"
                header="Brand"
                style={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="quantity"
                header="Availability"
                style={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="itemDeal"
                header="Deal"
                style={{ fontSize: "12px" }}
              ></Column>
            </DataTable>
          )}

        <Divider />
        <h1 style={{ fontSize: "14px", color: "black" }}>
          Cancellation Reason
        </h1>
        <Divider />
        {currentOrderDetails &&
          currentOrderDetails["ordercancelled"] &&
          currentOrderDetails["cancellationReason"] != "" && (
            <h1 style={{ fontSize: "12px", color: "#898080" }}>
              Order Cancelled on:{" "}
              <span style={{ fontSize: "12px", color: "#bcb7b7" }}>
                {currentOrderDetails["ordercancelled"]["at"]}
              </span>
            </h1>
          )}
        {currentOrderDetails &&
          currentOrderDetails["cancellationReason"] != "" && (
            <h1 style={{ fontSize: "12px", color: "#898080" }}>
              <span style={{ fontSize: "12px", color: "#bcb7b7" }}>
                {currentOrderDetails["cancellationReason"]}
              </span>
            </h1>
          )}
      </Dialog>

      <Dialog
        header="Change Order Status"
        visible={statusModal}
        style={{ width: "50vw" }}
        onHide={() => setStatusModal(false)}
        modal
      >
        {currentOrderDetails && currentOrderDetails["orderStatus"] && (
          <h1 style={{ fontSize: "12px", color: "#898080" }}>
            Current order status:{" "}
            <span style={{ fontSize: "12px", color: "black" }}>
              {currentOrderDetails["orderStatus"]}
            </span>
          </h1>
        )}

        {currentOrderDetails &&
          currentOrderDetails["orderStatus"] != "Pending" && (
            <>
              <h1 style={{ fontSize: "12px", color: "#898080" }}>
                Available order status:{" "}
              </h1>{" "}
            </>
          )}
        {currentOrderDetails &&
          currentOrderDetails["orderStatus"] != "Pending" && (
            <Dropdown
              value={selectedStatus}
              options={statuses}
              onChange={(e) => {
                setSelectedStatus(e.value);
              }}
              optionLabel="name"
              placeholder="Select a Status"
              style={{ width: "100%", fontSize: "12px" }}
            />
          )}
        {selectedStatus && selectedStatus.name === "Cancelled" && (
          <>
            <h1 style={{ fontSize: "12px", color: "#898080" }}>
              Cancellation reason:{" "}
            </h1>
            <InputTextarea
              value={cancelValue}
              onChange={(e) => setCancelValue(e.target.value)}
              rows={5}
              style={{ width: "100%" }}
            />
          </>
        )}
        {selectedStatus && selectedStatus.name === "Shipped" && (
          <>
            <h1 style={{ fontSize: "12px", color: "#898080" }}>
              Courier Shipping ID:{" "}
            </h1>
            <InputText
              value={shippingIDValue}
              onChange={(e) => setShippingIDValue(e.target.value)}
              style={{ width: "100%" }}
            />
          </>
        )}
        {currentOrderDetails &&
          currentOrderDetails["orderStatus"] === "Pending" && (
            <>
              <h1 style={{ fontSize: "12px", color: "#898080" }}>
                Payment Not Successful:{" "}
              </h1>
              <Dropdown
                value={selectedResponse}
                options={paymentNotSuccess}
                onChange={(e) => {
                  setSelectedResponse(e.value);
                }}
                optionLabel="name"
                placeholder="Select a Response"
                style={{ width: "100%", fontSize: "12px" }}
              />
            </>
          )}
        <div style={{ float: "right" }}>
          <Button
            label="Update"
            icon="pi pi-check"
            className="p-button-sm"
            style={{ marginTop: "30px" }}
            onClick={updateOrder}
          />
        </div>
      </Dialog>
    </>
  );
};
