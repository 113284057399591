import { InputText } from 'primereact/inputtext';
import React, { useState, useEffect, useRef } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { RadioButton } from 'primereact/radiobutton';
import "./customButton.css"
import { PreSignedUrlService } from '../service/PreSignedUrlService';
import { Toast } from 'primereact/toast';
import { CategoryService } from '../service/CategoryService';
import { BrandService } from '../service/BrandsService';
import { DealService } from '../service/DealService';
import configData from "../config.json";

export const DealsFormLayout = () => {
    const [radio, setRadio] = useState('Brands');
    const [category, setCategory] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedTypeD,setSelectedTypeD]=useState(null);
    const [brands, setBrands] = useState(null);
    const [deal, setDeal] = useState(null);
    const toast = useRef(null);
    const [image, setImage] = useState("")
    const [selectedBrand, setSelectedBrand] = useState(null);
    const onCategoryChange = (e) => {
        setSelectedCategory(e.value);
    }
    const onTypeDChange = (e) => {
        setSelectedTypeD(e.value);
    }
    const onBrandChange = (e) => {
        setSelectedBrand(e.value);
    }
    useEffect(() => {
        const categoryService = new CategoryService();
        const config = {
            method: 'get',
            url: configData.SERVER_URL+'admin/getCategories',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        categoryService.getCategories(config).then(data => setCategory(data));
        const brandsService = new BrandService();
        const config2 = {
            method: 'get',
            url: configData.SERVER_URL+'admin/admin/brands',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        brandsService.getBrands(config2).then(data => setBrands(data));
    }, []);

    const onTemplateUpload = (e) => {
        onUpload(e.files)
    }
    const onUpload = (e) => {
        const data = {
            "fileName":e[0].name,
            "fileType":e[0].type.split("/")[1],
        }
        const objectUrl = e[0]
        const type = e[0].type
        const config = {
            method: 'post',
            url: configData.SERVER_URL+'home/util/presignedurl',
            headers: {
                'Content-Type' : 'application/json'
            },
            data: data
        }
        const preSignedUrlService = new PreSignedUrlService();
        preSignedUrlService.generatePreSignedUrl(config).then(data => {if (data.url !== "") {
            setImage(data.url);
            preSignedUrlService.uploadOnGCP(data.url,objectUrl,type ).then(resp =>{
                toast.current.show({severity:'success', summary: 'Success', detail:'Image uploaded successfully', life: 3000});
            }).catch((error)=>{
                toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
            })
        }else{
            toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
        }});
    }
    const onSubmit = () =>{
        const dealService = new DealService();
        const t = selectedTypeD.name?selectedTypeD.name:"off"
        const d = deal +" " +t
        const body = {
            "name": d,
            "images": image,
            "forSpecificCategory":radio==="Category"?true:false,
            "categoryName":radio==="Category"?selectedCategory['name']:"-",
            "forSpecificBrand":radio==="Brands"?true:false,
            "brandName":radio==="Brands"?selectedBrand['name']:"-",
        }
        const config = {
            method: 'post',
            url: configData.SERVER_URL+'home/deals',
            headers: {
                'Content-Type' : 'application/json'
            },
            data: body
        }
        dealService.addDeals(config).then(data => {if (data.status === "success") {
            toast.current.show({severity:'success', summary: 'Success', detail:'Deal added successfully', life: 3000});
        }else{
            toast.current.show({severity:'error', summary: 'Error', detail:'Error while adding a new deal', life: 3000});
        }});
  }
    return (
        <>
        <Toast ref={toast} />
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Add Deal</h5>
                    <div className="p-fluid formgrid grid">
                        <div className="p-field-radiobutton">
                            <RadioButton inputId="radio1" name="radio" value="Brands" onChange={(e) => setRadio(e.value)} checked={radio === 'Brands'} />
                            <label htmlFor="radio1">  Brand</label>
                        </div>
                        <div className="p-field-radiobutton" style={{marginLeft:"20px"}}>
                            <RadioButton inputId="radio2" name="radio" value="Category" onChange={(e) => setRadio(e.value)} checked={radio === 'Category'} />
                            <label htmlFor="radio2">  Category</label>
                        </div>
                        {radio==="Brands" && <div className="field col-12"  style={{marginTop:"20px"}} >
                            <label htmlFor="dealName">Brand</label>
                            <Dropdown value={selectedBrand} options={brands} onChange={onBrandChange} optionLabel="name" placeholder="Select a Brand" />
                        </div>}
                        {radio==="Category" && <div className="field col-12" style={{marginTop:"20px"}}> 
                            <label htmlFor="dealName">Category</label>
                            <Dropdown value={selectedCategory} options={category} onChange={onCategoryChange} optionLabel="name" placeholder="Select a Category" />
                        </div>}
                        <div className="field col-12">
                            <label htmlFor="dealCategory">Discount value</label>
                            <InputText id="dealCategory" type="text" onChange={(e)=>setDeal(e.target.value)}/>
                        </div>
                        <div className="field col-12">
                            <label htmlFor="dealCategory">Discount Type</label>
<Dropdown value={selectedTypeD} options={["%","off"]} onChange={onTypeDChange} placeholder="Select a Type" />
                                

                        </div>
                        <div className="field col-12">
                        <label htmlFor="dealImage">Deal image</label>
                        <FileUpload name="demo[]" customUpload={true} uploadHandler={onTemplateUpload} onUpload={onTemplateUpload} accept="image/*" maxFileSize={1000000}
                                emptyTemplate={<p className="p-m-3" style={{fontSize:"15px"}}>Drag and drop files to here to upload.</p>} />
                        </div>
                        <div className="field col-12">
                        <Button className="p-button-rounded formButton" onClick={onSubmit}>Add deal</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}