import React, { useState, useEffect, useRef } from "react";
import S3 from "react-aws-s3";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { CategoryService } from "../service/CategoryService";
import { ProductService } from "../service/ProductService";
import { BrandService } from "../service/BrandsService";
import { Dropdown } from "primereact/dropdown";
import configData from "../config.json";
import { CONFIG } from "../utils/constants";

export const Crud = () => {
  let emptyProduct = {
    name: "",
    images: [],
    description: "",
    specifications: "",
    category: null,
    price: 0,
    quantity: 0,
    inventoryStatus: "INSTOCK",
  };
  const [edit, setEditUpdate] = useState(false);
  const [products, setProducts] = useState(null);
  const [parentProducts, setParentProducts] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [relistProductDialog, setRelistProductDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const [brands, setBrands] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedParentProduct, setSelectedParentProduct] = useState(null);
  const [category, setCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [productId, setProductId] = useState("");
  const fileInput = useRef();

  const onBrandChange = (e) => {
    setSelectedBrand(e.value);
  };

  const getProducts = () => {
    const productService = new ProductService();

    const config22 = {
      method: "get",
      url: configData.SERVER_URL + "admin/item",
      headers: {
        "Content-Type": "application/json",
      },
    };
    productService.getProduct(config22).then((data) => {
      data.forEach((p) => {
        p.stockAvailable = p.inventory?.stockAvailable;
      });
      setProducts(data);
    });

    productService
      .getProduct({ ...config22, url: config22.url + "?parent=true" })
      .then((data) => setParentProducts(data));
  };

  useEffect(() => {
    const categoryService = new CategoryService();
    const config = {
      method: "get",
      url: configData.SERVER_URL + "admin/getCategories",
      headers: {
        "Content-Type": "application/json",
      },
    };
    categoryService.getCategories(config).then((data) => setCategory(data));

    getProducts();

    const brandsService = new BrandService();
    const config2 = {
      method: "get",
      url: configData.SERVER_URL + "admin/admin/brands",
      headers: {
        "Content-Type": "application/json",
      },
    };
    brandsService.getBrands(config2).then((data) => setBrands(data));
  }, []);

  useEffect(() => {
    getProducts();
  }, [
    productDialog,
    // edit,
    // deleteProductDialog,
    relistProductDialog,
  ]);

  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const openNew = () => {
    setProduct(emptyProduct);
    setSelectedBrand(null);
    setSelectedCategory(null);
    setSelectedParentProduct(null);
    setSubmitted(false);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };
  const hideRelistProductDialog = () => {
    setRelistProductDialog(false);
  };

  const [images, setImage] = useState([]);
  const onTemplateUpload = (e) => {
    onUpload(e.files);
  };

  const onUpload = (event) => {
    let file = fileInput.current.files[0];
    let newFileName = fileInput.current.files[0].name.replace(/\..+$/, "");
    const config = CONFIG;
    const ReactS3Client = new S3(config);
    ReactS3Client.uploadFile(file, newFileName).then((data) => {
      if (data.status === 204) {
        const nI = [...images];
        nI.push(data.location);
        setImage(nI);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Image uploaded successfully",
          life: 3000,
        });
      } else {
        console.log("fail");
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while upload image",
          life: 3000,
        });
      }
    });
  };
  const saveProduct = () => {
    setSubmitted(true);
    const productService = new ProductService();

    const body = {
      name: product.name,
      description: product.description,
      brand: selectedBrand?._id,
      category: selectedCategory?._id,
      price: product.price,
      quantity: product.quantity,
      images: images,
      parent: product.parent,
      stockAvailable: product.stockAvailable,
    };
    if (edit) {
      const config = {
        method: "put",
        url: configData.SERVER_URL + "item/" + productId,
        headers: {
          "Content-Type": "application/json",
        },
        data: body,
      };
      productService.updateProduct(config).then((data) => {
        if (data.status === "success") {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Product updated successfully",
            life: 3000,
          });
          setProductDialog(false);
          setProduct(emptyProduct);
          setEditUpdate(false);
          setProductId("");
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Error while updating product",
            life: 3000,
          });
        }
      });
      return;
    }
    const config = {
      method: "post",
      url: configData.SERVER_URL + "admin/item",
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
    };
    productService.addProduct(config).then((data) => {
      if (data.status === "success") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Product added successfully",
          life: 3000,
        });
        setProductDialog(false);
        setProduct(emptyProduct);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while adding a new product",
          life: 3000,
        });
      }
    });
  };

  const editProduct = (product) => {
    setProduct({ ...product });
    setEditUpdate(true);
    setProductId(product._id);
    setSelectedBrand(product.brand);
    setSelectedParentProduct(product.parent);
    setSelectedCategory(product.category);

    if (product.images !== "") {
      setImage(product.images);
    } else {
      setImage([product.images]);
    }

    setProductDialog(true);
  };
  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setProductId(product._id);
    setDeleteProductDialog(true);
  };
  const confirmRelistProduct = (product) => {
    setProduct(product);
    setProductId(product._id);
    setRelistProductDialog(true);
  };
  const deleteProduct = () => {
    const productService = new ProductService();
    const config = {
      method: "delete",
      url: configData.SERVER_URL + "admin/unlist/item/" + productId,
      headers: {
        "Content-Type": "application/json",
      },
    };
    productService.deleteProduct(config).then((data) => {
      if (data.status === "success") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Product unlisted successfully",
          life: 3000,
        });
        setDeleteProductDialog(false);
        setProduct(emptyProduct);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while unlisting product",
          life: 3000,
        });
      }
    });
  };

  const relistProduct = () => {
    const productService = new ProductService();
    const config = {
      method: "put",
      url: configData.SERVER_URL + "admin/relist/item/" + productId,
      headers: {
        "Content-Type": "application/json",
      },
    };
    productService.relistProduct(config).then((data) => {
      if (data.status === "success") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Product relisted successfully",
          life: 3000,
        });
        setRelistProductDialog(false);
        setProduct(emptyProduct);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while relisting product",
          life: 3000,
        });
      }
    });
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };
  const onInputChange = (e, name) => {
    if (name === "stock") {
      const re = /^[0-9\b]+$/;
      if (!re.test(e.target.value)) {
        return;
      }
    }
    const val = (e.target && e.target.value) || "";
    let _product = { ...product };
    _product[`${name}`] = val;
    setProduct(_product);
  };
  const onCategoryChange = (e) => {
    let _product = { ...product };
    _product.category = e.value;
    setSelectedCategory(e.value);
    setProduct(_product);
  };
  const onParentProductChange = (e) => {
    let _product = { ...product };
    _product.parent = e.value?._id;
    setSelectedParentProduct(e.value);
    setProduct(_product);
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-info"
          onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const codeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Code</span>
        {rowData._id.substring(0, 8)}
      </>
    );
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Name</span>
        {rowData.name}
      </>
    );
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Image</span>
        <img
          src={rowData.images[0]}
          alt={rowData.images[0]}
          className="shadow-2"
          width="90"
        />
      </>
    );
  };

  const priceBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Price</span>
        {rowData ? (rowData.price ? formatCurrency(rowData.price) : "") : ""}
      </>
    );
  };

  const categoryBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Category</span>
        {rowData.category?.name}
      </>
    );
  };
  const brandBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Brand</span>
        {rowData.brand?.name}
      </>
    );
  };

  const activestatusBodyTemplate = (rowData) => {
    if (rowData.status == "Active") {
      return (
        <>
          <span className="p-column-title">Status</span>
          <span className={`product-badge status-instock`}>
            {rowData.status}
          </span>
        </>
      );
    } else {
      return (
        <>
          <span className="p-column-title">Status</span>
          <span className={`product-badge status-lowstock`}>Not Active</span>
        </>
      );
    }
  };

  const quantitystatusBodyTemplate = (rowData) => {
    let qstatus = "OUTOFSTOCK";
    if (rowData?.inventory?.stockAvailable > 15) {
      qstatus = "INSTOCK";
    } else if (
      rowData?.inventory?.stockAvailable > 0 &&
      rowData?.inventory?.stockAvailable < 15
    ) {
      qstatus = "LOWSTOCK";
    } else if (rowData?.inventory?.stockAvailable < 1) {
      qstatus = "OUTOFSTOCK";
    }
    return (
      <>
        <span className="p-column-title">Availability</span>
        <span className={`product-badge status-${qstatus.toLowerCase()}`}>
          {qstatus}
        </span>
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editProduct(rowData)}
        />
        {rowData.status === "Active" && (
          <Button
            icon="pi pi-trash"
            className="p-button-rounded p-button-warning"
            onClick={() => confirmDeleteProduct(rowData)}
          />
        )}
        {rowData.status === "not active" && (
          <Button
            icon="pi pi-list"
            className="p-button-rounded p-button-info"
            onClick={() => confirmRelistProduct(rowData)}
          />
        )}
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Manage Products</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const productDialogFooter = (
    <>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        onClick={saveProduct}
      />
    </>
  );
  const deleteProductDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteProduct}
      />
    </>
  );
  const relistProductDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideRelistProductDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={relistProduct}
      />
    </>
  );

  const [selectedCatF, setSelectedCatF] = useState(null);
  const catItemTemplate = (option) => {
    return (
      <span
        style={{
          fontSize: "14px",
          fontWeight: 600,
          textTransform: "capitalize",
        }}
      >
        {option.name}
      </span>
    );
  };
  const onCatChange = (e) => {
    dt.current.filter(e.value ? e.value.name : e.value, "category", "equals");
    setSelectedCatF(e.value);
  };
  const catFilter = (
    <Dropdown
      value={selectedCatF}
      options={category}
      onChange={onCatChange}
      itemTemplate={catItemTemplate}
      optionLabel="name"
      placeholder="Select a Category"
      option
      showClear
      style={{ width: "110px", fontSize: "10px" }}
    />
  );

  const [selectedBrandF, setSelectedBrandF] = useState(null);
  const brandItemTemplate = (option) => {
    return (
      <span
        style={{
          fontSize: "14px",
          fontWeight: 600,
          textTransform: "capitalize",
        }}
      >
        {option.name}
      </span>
    );
  };
  const onBrandFChange = (e) => {
    dt.current.filter(e.value ? e.value.name : e.value, "brand", "equals");
    setSelectedBrandF(e.value);
  };
  const brandFilter = (
    <Dropdown
      value={selectedBrandF}
      options={brands}
      onChange={onBrandFChange}
      itemTemplate={brandItemTemplate}
      optionLabel="name"
      placeholder="Select a Brand"
      option
      showClear
      style={{ width: "110px", fontSize: "10px" }}
    />
  );
  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={products}
            selection={selectedProducts}
            onSelectionChange={(e) => setSelectedProducts(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
            globalFilter={globalFilter}
            emptyMessage="No products found."
            header={header}
          >
            <Column
              field="_id"
              header="Product Id"
              sortable
              body={codeBodyTemplate}
              style={{ fontSize: "12px" }}
            ></Column>
            <Column
              field="name"
              header="Name"
              sortable
              body={nameBodyTemplate}
              style={{ fontSize: "12px" }}
            ></Column>
            <Column
              field="brand"
              header="Brand"
              body={brandBodyTemplate}
              style={{ fontSize: "12px" }}
              filterElement={brandFilter}
            ></Column>
            <Column
              header="Image"
              body={imageBodyTemplate}
              style={{ fontSize: "12px" }}
            ></Column>
            <Column
              field="price"
              header="Price"
              body={priceBodyTemplate}
              sortable
              style={{ fontSize: "12px" }}
            ></Column>

            <Column
              field="category"
              header="Category"
              body={categoryBodyTemplate}
              style={{ fontSize: "12px" }}
              filter
              filterElement={catFilter}
            ></Column>
            <Column
              field="quantity"
              header="Availability"
              body={quantitystatusBodyTemplate}
              sortable
              style={{ fontSize: "12px" }}
            ></Column>
            <Column
              field="status"
              header="Status"
              body={activestatusBodyTemplate}
              style={{ fontSize: "12px" }}
            ></Column>
            <Column
              body={actionBodyTemplate}
              style={{ fontSize: "12px" }}
            ></Column>
          </DataTable>

          <Dialog
            visible={productDialog}
            style={{ width: "450px" }}
            header="Product Details"
            modal
            className="p-fluid"
            footer={productDialogFooter}
            onHide={hideDialog}
          >
            {product.images && product.images.length && (
              <img
                src={product.images[0]}
                alt={product.images[0]}
                width="150"
                className="mt-0 mx-auto mb-5 block shadow-2"
              />
            )}
            <div className="field">
              <label htmlFor="name">Name</label>
              <InputText
                id="name"
                value={product.name}
                onChange={(e) => onInputChange(e, "name")}
                required
                autoFocus
                className={classNames({
                  "p-invalid": submitted && !product.name,
                })}
              />
              {submitted && !product.name && (
                <small className="p-invalid">Name is required.</small>
              )}
            </div>
            <div className="field">
              <label htmlFor="description">Description</label>
              <InputTextarea
                id="description"
                value={product.description}
                onChange={(e) => onInputChange(e, "description")}
                required
                rows={3}
                cols={20}
              />
            </div>
            <div className="field">
              <label htmlFor="description">Brand</label>
              <Dropdown
                value={selectedBrand}
                options={brands}
                onChange={onBrandChange}
                optionLabel="name"
                placeholder={selectedBrand?.name || "Select a Brand"}
              />
            </div>
            <div className="field">
              <label className="mb-3">Category</label>
              <Dropdown
                value={selectedCategory?.name}
                options={category}
                onChange={onCategoryChange}
                optionLabel="name"
                placeholder={selectedCategory?.name || "Select a Category"}
              />
            </div>

            <div className="field">
              <label htmlFor="description">Parent</label>
              <Dropdown
                value={selectedParentProduct}
                options={parentProducts}
                onChange={onParentProductChange}
                optionLabel="name"
                placeholder={selectedParentProduct?.name || "Select a Parent"}
              />
            </div>

            {selectedParentProduct ? (
              <>
                <div className="field">
                  <label htmlFor="description">Stock Available</label>
                  <br />
                  <input
                    type="number"
                    value={product.stockAvailable}
                    onChange={(e) => onInputChange(e, "stockAvailable")}
                  />
                </div>

                <div className="field">
                  <label htmlFor="description">Price per unit</label>
                  <br />
                  <input
                    type="number"
                    value={product.price}
                    onChange={(e) => onInputChange(e, "price")}
                  />
                </div>
              </>
            ) : null}

            <FileUpload
              ref={fileInput}
              name="demo[]"
              customUpload={true}
              multiple
              uploadHandler={onTemplateUpload}
              onUpload={onTemplateUpload}
              accept="image/*"
              maxFileSize={1000000}
              emptyTemplate={
                <p className="p-m-3" style={{ fontSize: "15px" }}>
                  Drag and drop files to here to upload.
                </p>
              }
              auto
            />
          </Dialog>

          {/* <Dialog
            visible={deleteProductDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteProductDialogFooter}
            onHide={hideDeleteProductDialog}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {product && (
                <span>
                  Are you sure you want to unlist <b>{product.name}</b> from
                  user app?
                </span>
              )}
            </div>
          </Dialog> */}

          {/* <Dialog
            visible={relistProductDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={relistProductDialogFooter}
            onHide={hideRelistProductDialog}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {product && (
                <span>
                  Are you sure you want to relist <b>{product.name}</b> on user
                  app?
                </span>
              )}
            </div>
          </Dialog> */}
        </div>
      </div>
    </div>
  );
};
