import axios from 'axios';

export class OrderService {

    getOrders(config) {
        return axios(config).then(res => res.data);
    }

    updateOrders(config){
        return axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
           return error
        })
    }
}