import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { BrandService } from "../service/BrandsService";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Toast } from 'primereact/toast';
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { CategoryService } from "../service/CategoryService";
import { Dropdown } from 'primereact/dropdown';
import configData from "../config.json";
export const BrandsTable = () => {
    let emptyBrand = {
        name: "",
        images: null,
        category:null,
    };
    const [brands, setBrands] = useState(null);
    const [deleteBrandDialog, setDeleteBrandDialog] = useState(false);
    const [relistBrandDialog, setRelistBrandDialog] = useState(false);
    const [brandDetails, setBrandDetails] = useState(emptyBrand);
    const toast = useRef(null);
    const [category, setCategory] = useState(null);
    const [edit,setEditUpdate] = useState(false)
    const [images, setImage] = useState("")
    const [bid,setBid] = useState("")
    useEffect(() => {
        const brandsService = new BrandService();
        const config = {
            method: "get",
            url: configData.SERVER_URL+"admin/admin/brands",
            headers: {
                "Content-Type": "application/json",
            },
        };
        brandsService.getBrands(config).then((data) => setBrands(data));
        const categoryService = new CategoryService();
        const config1 = {
            method: "get",
            url: configData.SERVER_URL+"admin/getCategories",
            headers: {
                "Content-Type": "application/json",
            },
        };
        categoryService.getCategories(config1).then((data) => setCategory(data));
    }, []);
    useEffect(() => {
        const brandsService = new BrandService();
        const config = {
            method: "get",
            url: configData.SERVER_URL+"admin/admin/brands",
            headers: {
                "Content-Type": "application/json",
            },
        };
        brandsService.getBrands(config).then((data) => setBrands(data));
    }, [deleteBrandDialog,relistBrandDialog,edit,brandDetails]);
    const activestatusBodyTemplate = (rowData) => {
        if (rowData.status == "Active") {
            return (
                <>
                    <span className={`product-badge status-instock`}>{rowData.status}</span>
                </>
            );
        } else {
            return (
                <>
                    <span className={`product-badge status-outofstock`}>Not Active</span>
                </>
            );
        }
    };
    const hideDeleteBrandDialog = () => {
        setDeleteBrandDialog(false);
    };
    const hideRelistBrandDialog = () => {
        setRelistBrandDialog(false);
    };
    const onCategoryChange = (e) => {
        let _brand = { ...brandDetails };
        _brand["category"] = e.value;
        setBrandDetails(_brand);
    };
    const deleteBrand = () => {
        const brandDetailsService = new BrandService();
        const config = {
            method: "put",
            url: configData.SERVER_URL+"admin/brand/"+bid,
            headers: {
                "Content-Type": "application/json",
            },
            data: { status: "Active" },
        };
        brandDetailsService.deleteBrand(config).then((data) => {
            if (data) {
                toast.current.show({ severity: "success", summary: "Success", detail: "Brand unlisted successfully", life: 3000 });
                setDeleteBrandDialog(false);
                setBrandDetails(emptyBrand);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error while unlisting brand", life: 3000 });
            }
        });
    };

    const relistProduct = () => {
        const brandDetailsService = new BrandService();
        const config = {
            method: "put",
            url: configData.SERVER_URL+"admin/brand/"+bid,
            headers: {
                "Content-Type": "application/json",
            },
            data: { status: "Not Active" },
        };
        brandDetailsService.relistBrand(config).then((data) => {
            if (data) {
                toast.current.show({ severity: "success", summary: "Success", detail: "Brand relisted successfully", life: 3000 });
                setRelistBrandDialog(false);
                setBrandDetails(emptyBrand);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error while relisting brand", life: 3000 });
            }
        });
    };
    const [brandUpdateDialog, setBrandUpdateDialog] = useState(false);
    const updateBrand = () => {
        const brandService = new BrandService();
        const body = {
            "name":brandDetails.name,
            "category":brandDetails.category,
            "images":images
        }
        if (edit === true){
            const config = {
                method: 'put',
                url: configData.SERVER_URL+'admin/brand/update/'+bid,
                headers: {
                    'Content-Type' : 'application/json'
                },
                data: body
            }
            brandService.updateBrand(config).then(data => {if (data.status === "success") {
                toast.current.show({severity:'success', summary: 'Success', detail:'Brand updated successfully', life: 3000});
                setBrandUpdateDialog(false);
                setBrandDetails(emptyBrand);
                setEditUpdate(false);
                setBid("");
            }else{
                toast.current.show({severity:'error', summary: 'Error', detail:'Error while updating brand', life: 3000});
            }});
            return
        }
    };

    const brandUpdateDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={()=>{setBrandUpdateDialog(false)}} />
            <Button label="Save" icon="pi pi-check" className="p-button-text"  onClick={updateBrand}/>
        </>
    );

    const deleteBrandDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteBrandDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteBrand} />
        </>
    );
    const relistBrandDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideRelistBrandDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={relistProduct} />
        </>
    );

    const editBrand = (brand) => {
        setBrandDetails({ ...brand });
        setEditUpdate(true)
        setBid(brand._id)
        setImage(brand.images)
        setBrandUpdateDialog(true);
    };
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _brand = { ...brandDetails };
        _brand[`${name}`] = val;
        setBrandDetails(_brand)
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editBrand(rowData)} />
                {rowData.status =="Active" && <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => {setBrandDetails(rowData);setBid(rowData["_id"]);setDeleteBrandDialog(true);}} />}
                {rowData.status =="Not Active" && <Button icon="pi pi-list" className="p-button-rounded p-button-info" onClick={() => {setBrandDetails(rowData);setBid(rowData["_id"]);setRelistBrandDialog(true);}} />}

            </div>
        );
    };

    const [selectedStatF, setSelectedStatF] = useState(null);
    const dt = useRef(null);
    const statItemTemplate = (option) => {
        return <span style={{fontSize:"14px",fontWeight:600,textTransform:'capitalize'}}>{option}</span>;
    }
    const onStatChange = (e) => {
        dt.current.filter(e.value, 'status', 'equals');
        setSelectedStatF(e.value);
    }
    const statFilter = <Dropdown value={selectedStatF} options={["Active","Not Active"]} onChange={onStatChange} itemTemplate={statItemTemplate} placeholder="Select a Status" option showClear style={{width:"110px",fontSize:'10px'}}/>;
    const [selectedCatF, setSelectedCatF] = useState(null);
    const catItemTemplate = (option) => {
        return <span style={{fontSize:"14px",fontWeight:600,textTransform:'capitalize'}}>{option.name}</span>;
    }
    const onCatChange = (e) => {
        dt.current.filter(e.value?e.value.name:e.value, 'category', 'equals');
        setSelectedCatF(e.value);
    }
    const catFilter = <Dropdown value={selectedCatF} options={category} onChange={onCatChange} itemTemplate={catItemTemplate} optionLabel="name" placeholder="Select a Category" option showClear style={{width:"110px",fontSize:'10px'}}/>;

    return (
        <>
         <Toast ref={toast} />
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <h5>Brands</h5>
                        <DataTable ref = {dt} value={brands} className="datatable-responsive" rows={5} paginator>
                            <Column header="Image" body={(data) => <img src={data.images} width="50" />} />
                            <Column field="name" header="Name" sortable style={{ fontSize: "12px" }} />
                            <Column field="category" header="Category" style={{ fontSize: "12px" }} filter filterElement={catFilter}/>
                            <Column field="status" header="Status" body={activestatusBodyTemplate} filter filterElement={statFilter}/>
                            <Column body={actionBodyTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
            <Dialog visible={brandUpdateDialog} style={{ width: "450px" }} header="Brand Details" modal className="p-fluid" footer={brandUpdateDialogFooter} onHide={()=>{setBrandUpdateDialog(false)}}>
                            {brandDetails.images && <img src={brandDetails.images} alt={brandDetails.images} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                            <div className="field">
                                <label htmlFor="name">Name</label>
                                <InputText id="name" value={brandDetails.name} onChange={(e) => onInputChange(e, "name")} required autoFocus />
                            </div>
                            <div className="field">
                                <label className="mb-3">Category</label>
                                <div className="formgrid grid">
                                    {category &&
                                        category.map((data) => {
                                            return <div className="field-radiobutton col-6">
                                                        <RadioButton inputId="category1" name="category" value={data['name']} onChange={onCategoryChange} checked={brandDetails.category === data['name']} />
                                                        <label htmlFor="category1">{data['name']}</label>
                                                    </div>
                                        })}
                                </div>
                            </div>

                        </Dialog>
            <Dialog visible={deleteBrandDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteBrandDialogFooter} onHide={hideDeleteBrandDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {brandDetails && (
                        <span>
                            Are you sure you want to unlist <b>{brandDetails.name}</b> ?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={relistBrandDialog} style={{ width: "450px" }} header="Confirm" modal footer={relistBrandDialogFooter} onHide={hideRelistBrandDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {brandDetails && (
                        <span>
                            Are you sure you want to relist brand <b>{brandDetails.name}</b> ?
                        </span>
                    )}
                </div>
            </Dialog>
        </>
    );
};
