import React,{useState,useEffect,useRef} from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DealService } from '../service/DealService';
import { Dialog } from "primereact/dialog";
import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { CategoryService } from "../service/CategoryService";
import { BrandService } from "../service/BrandsService";
import { Dropdown } from "primereact/dropdown";
import configData from "../config.json";

export const DealsTable = () => {
    let emptyDeals = {
        name: "",
        images: null,
        forSpecificCategory:false,
        categoryName:"-",
        forSpecificBrand:false,
        brandName:"-",
    };
    const [deals, setDeals] = useState(null);
    const [deleteDealDialog, setDeleteDealDialog] = useState(false);
    const [relistDealDialog, setRelistDealDialog] = useState(false);
    const [dealDetails, setDealDetails] = useState(emptyDeals);
    const toast = useRef(null);
    const [edit,setEditUpdate] = useState(false)
    const [images, setImage] = useState("")
    const [dealUpdateDialog, setDealUpdateDialog] = useState(false);
    const [did,setDid] = useState("")

    const [brands, setBrands] = useState(null);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [category, setCategory] = useState(null);

    useEffect(() => {
        const dealsService = new DealService();
        const config = {
            method: 'get',
            url: configData.SERVER_URL+'dmin/deals',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        dealsService.getDeals(config).then(data => setDeals(data));


        const categoryService = new CategoryService();
        const config1 = {
            method: "get",
            url: configData.SERVER_URL+"admin/getCategories",
            headers: {
                "Content-Type": "application/json",
            },
        };
        categoryService.getCategories(config1).then((data) => setCategory(data));
        const brandsService = new BrandService();
        const config2 = {
            method: "get",
            url: configData.SERVER_URL+"admin/admin/brands",
            headers: {
                "Content-Type": "application/json",
            },
        };
        brandsService.getBrands(config2).then((data) => setBrands(data));
    }, []);
    useEffect(() => {
        const dealsService = new DealService();
        const config = {
            method: 'get',
            url: configData.SERVER_URL+'admin/deals',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        dealsService.getDeals(config).then(data => setDeals(data));
    }, [deleteDealDialog,relistDealDialog,edit,dealDetails]);

    const activestatusBodyTemplate = (rowData) => {
        if (rowData.status == "Active") {
            return (
                <>
                    <span className={`product-badge status-instock`}>{rowData.status}</span>
                </>
            );
        } else {
            return (
                <>
                    <span className={`product-badge status-outofstock`}>Not Active</span>
                </>
            );
        }
    };
    const hideDeleteDealDialog = () => {
        setDeleteDealDialog(false);
    };
    const hideRelistDealDialog = () => {
        setRelistDealDialog(false);
    };
    const deleteDeal = () => {
        const dealService = new DealService();
        const config = {
            method: "put",
            url: configData.SERVER_URL+"home/deals/"+did,
            headers: {
                "Content-Type": "application/json",
            },
            data: { status: "Active" },
        };
        dealService.deleteDeal(config).then((data) => {
            if (data) {
                toast.current.show({ severity: "success", summary: "Success", detail: "Banner unlisted successfully", life: 3000 });
                setDeleteDealDialog(false);
                setDealDetails(emptyDeals);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error while unlisting banner", life: 3000 });
            }
        });
    };

    const relistDeal = () => {
        const dealService = new DealService();
        const config = {
            method: "put",
            url: configData.SERVER_URL+"home/deals/"+did,
            headers: {
                "Content-Type": "application/json",
            },
            data: { status: "Not Active" },
        };
        dealService.relistDeal(config).then((data) => {
            if (data) {
                toast.current.show({ severity: "success", summary: "Success", detail: "Banner relisted successfully", life: 3000 });
                setRelistDealDialog(false);
                setDealDetails(emptyDeals);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error while relisting banner", life: 3000 });
            }
        });
    };


    const updateDeal = () => {
        const dealService = new DealService();
        if (edit === true){
            const config = {
                method: 'put',
                url: configData.SERVER_URL+"home/deals/update/"+did,
                headers: {
                    'Content-Type' : 'application/json'
                },
                data: dealDetails
            }
            dealService.updateDeals(config).then(data => {if (data.status === "success") {
                toast.current.show({severity:'success', summary: 'Success', detail:'Deal updated successfully', life: 3000});
                setDealUpdateDialog(false);
                setDealDetails(emptyDeals);
                setEditUpdate(false);
                setDid("");
            }else{
                toast.current.show({severity:'error', summary: 'Error', detail:'Error while updating deal', life: 3000});
            }});
            return
        }
    };

    const dealUpdateDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={()=>{setDealUpdateDialog(false)}} />
            <Button label="Save" icon="pi pi-check" className="p-button-text"  onClick={updateDeal}/>
        </>
    );

    const deleteDealDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDealDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteDeal} />
        </>
    );
    const relistDealDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideRelistDealDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={relistDeal} />
        </>
    );
    const editDeal = (deal) => {
        setDealDetails({ ...deal });
        setEditUpdate(true)
        setDid(deal._id)
        setSelectedBrand({"name":deal.brandName})
        setImage(deal.images)
        setDealUpdateDialog(true);
    };
    const onBrandChange = (e) => {
        let _deal = { ...dealDetails };
        _deal["brandName"] = e.value["name"];
        setDealDetails(_deal);
        setSelectedBrand(e.value);
    };
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _deal = { ...dealDetails };
        if (name==="forSpecificBrand"){
            _deal[`forSpecificBrand`] = true
            _deal[`categoryName`] = "-";
            _deal[`forSpecificCategory`] = false;
        }else if (name==="forSpecificCategory"){
            _deal[`forSpecificBrand`] = false;
            _deal[`brandName`] = "-";
            _deal[`forSpecificCategory`] = true;
        }else{
            _deal[`${name}`] = val;  
        }
        
        setDealDetails(_deal)
    };
    const onCategoryChange = (e) => {
        let _deal = { ...dealDetails };
        _deal["categoryName"] = e.value;
        setDealDetails(_deal);
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editDeal(rowData)} />
                {rowData.status =="Active" && <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => {setDealDetails(rowData);setDid(rowData["_id"]);setDeleteDealDialog(true);}} />}
                {rowData.status =="Not Active" && <Button icon="pi pi-list" className="p-button-rounded p-button-info" onClick={() => {setDealDetails(rowData);setDid(rowData["_id"]);setRelistDealDialog(true);}} />}

            </div>
        );
    };
    const [selectedStatF, setSelectedStatF] = useState(null);
    const dt = useRef(null);
    const statItemTemplate = (option) => {
        return <span style={{fontSize:"14px",fontWeight:600,textTransform:'capitalize'}}>{option}</span>;
    }
    const onStatChange = (e) => {
        dt.current.filter(e.value, 'status', 'equals');
        setSelectedStatF(e.value);
    }
    const statFilter = <Dropdown value={selectedStatF} options={["Active","Not Active"]} onChange={onStatChange} itemTemplate={statItemTemplate} placeholder="Select a Status" option showClear style={{width:"110px",fontSize:'10px'}}/>;

    return (
        <>
        <Toast ref={toast} />
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Deals</h5>
                    <DataTable ref={dt} value={deals} className="p-datatable-customers" rows={5} paginator>
                        <Column header="Image" body={(data) => <img src={data.images} alt={data.image} width="50" />} />
                        <Column field="name" header="Name" sortable style={{fontSize:"12px"}}/>
                        <Column field="brandName" header="Brand" sortable style={{fontSize:"12px"}}/>
                        <Column field="categoryName" header="Category" sortable style={{fontSize:"12px"}}/>
                        <Column field="status" header="Status" body={activestatusBodyTemplate} filter filterElement={statFilter}/>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
        </div>

        <Dialog visible={dealUpdateDialog} style={{ width: "450px" }} header="Deal Details" modal className="p-fluid" footer={dealUpdateDialogFooter} onHide={()=>{setDealUpdateDialog(false)}}>
                            {dealDetails.images && <img src={dealDetails.images} alt={dealDetails.images} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                            <div className="field">
                                <label htmlFor="name">Discount Value</label>
                                <InputText id="name" value={dealDetails.name} onChange={(e) => onInputChange(e, "name")} required autoFocus />
                            </div>
                            <div className="field">
                                <label htmlFor="name">Type</label>
                                <div className="field-radiobutton col-6">
                                    <RadioButton inputId="category1" name="category" value={dealDetails.categoryName} onChange={(e) => {onInputChange(e, "forSpecificCategory")}} checked={dealDetails.forSpecificCategory} />
                                    <label htmlFor="category1">Category</label>
                                    <RadioButton inputId="category1" name="category" value={dealDetails.brandName} onChange={(e) => {onInputChange(e, "forSpecificBrand")}} checked={dealDetails.forSpecificBrand} style={{marginLeft:"15px"}}/>
                                    <label htmlFor="category1">Brand</label>
                                </div>
                            </div>
                            {dealDetails.forSpecificCategory && <div className="field">
                                <label className="mb-3">Category</label>
                                <div className="formgrid grid">
                                    {category &&
                                        category.map((data) => {
                                            return <div className="field-radiobutton col-6">
                                                        <RadioButton inputId="category1" name="category" value={data['name']} onChange={onCategoryChange} checked={dealDetails.categoryName === data['name']} />
                                                        <label htmlFor="category1">{data['name']}</label>
                                                    </div>
                                        })}
                                </div>
                            </div>}
                            {dealDetails.forSpecificBrand && <div className="field">
                                <label htmlFor="description">Brand</label>
                                <Dropdown value={selectedBrand} options={brands} onChange={onBrandChange} optionLabel="name" placeholder={selectedBrand?selectedBrand["name"]:"Select a Brand"} />
                            </div>}

                        </Dialog>
            <Dialog visible={deleteDealDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteDealDialogFooter} onHide={hideDeleteDealDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {dealDetails && (
                        <span>
                            Are you sure you want to unlist <b>{dealDetails.name}</b> ?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={relistDealDialog} style={{ width: "450px" }} header="Confirm" modal footer={relistDealDialogFooter} onHide={hideRelistDealDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {dealDetails && (
                        <span>
                            Are you sure you want to relist deal <b>{dealDetails.name}</b> ?
                        </span>
                    )}
                </div>
            </Dialog>
        </>
    );
};
