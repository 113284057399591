import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Route, Switch } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";

import { Dashboard } from "./components/Dashboard";
import { AddBrandsFormLayout } from "./components/BrandFormLayout";
import { CategoryFormLayout } from "./components/CategoryFormLayout";
import { BannerFormLayout } from "./components/BannerFormLayout";
import { DealsFormLayout } from "./components/DealsFormLayout";
import { ItemFormLayout } from "./components/ItemFormLayout";
import { LoginPageComponent } from "./components/LoginPageComponent";
import { BrandsTable } from "./components/BrandsTable";
import { CategoryTable } from "./components/CategoryTable";
import { BannerTable } from "./components/BannerTable";
import { UserTable } from "./components/UsersTable";
import { DealsTable } from "./components/DealsTable";
import { TransactionsTable } from "./components/TransactionTable";
import { OrdersTable } from "./components/OrdersTable";
import { Crud } from "./pages/Crud";
import PrimeReact from "primereact/api";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./layout/flags/flags.css";
import "./layout/layout.scss";
import "./App.scss";

const App = () => {
  const [layoutMode, setLayoutMode] = useState("static");
  const [layoutColorMode, setLayoutColorMode] = useState("light");
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);

  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };
  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const menu = [
    {
      label: "Home",
      items: [
        {
          label: "Dashboard",
          icon: "pi pi-fw pi-home",
          to: "/dashboard",
        },
      ],
    },
    {
      label: "Brands",
      items: [
        {
          label: "Add Brand",
          icon: "pi pi-fw pi-plus-circle",
          to: "/addbrands",
        },
        {
          label: "Manage Brands",
          icon: "pi pi-fw pi-globe",
          to: "/managebrands",
        },
      ],
    },
    {
      label: "Categories",
      items: [
        {
          label: "Add Category",
          icon: "pi pi-fw pi-plus-circle",
          to: "/addcategory",
        },
        {
          label: "Manage Categories",
          icon: "pi pi-fw pi-microsoft",
          to: "/managecategory",
        },
      ],
    },
    {
      label: "Banners",
      items: [
        {
          label: "Add Banner",
          icon: "pi pi-fw pi-plus-circle",
          to: "/addbanner",
        },
        {
          label: "Manage Banners",
          icon: "pi pi-fw pi-ticket",
          to: "/managebanner",
        },
      ],
    },
    {
      label: "Products",
      items: [
        {
          label: "Manage Product",
          icon: "pi pi-fw pi-filter",
          to: "/crud",
        },
      ],
    },
    // {
    //   label: "Deals",
    //   items: [
    //     {
    //       label: "Add Deal",
    //       icon: "pi pi-fw pi-plus-circle",
    //       to: "/adddeal",
    //     },
    //     {
    //       label: "Manage Deals",
    //       icon: "pi pi-fw pi-id-card",
    //       to: "/managedeals",
    //     },
    //   ],
    // },
    {
      label: "Users",
      items: [
        {
          label: "Manage Users",
          icon: "pi pi-fw pi-users",
          to: "/manageusers",
        },
      ],
    },
    // {
    //   label: "Chat",
    //   items: [
    //     {
    //       label: "Manage Chats",
    //       icon: "pi pi-fw pi-comments",
    //       to: "/chat",
    //     },
    //   ],
    // },
    // {
    //   label: "Transactions",
    //   items: [
    //     {
    //       label: "Manage Transactions",
    //       icon: "pi pi-fw pi-credit-card",
    //       to: "/transactions",
    //     },
    //   ],
    // },
    {
      label: "Orders",
      items: [
        {
          label: "Manage Orders",
          icon: "pi pi-fw pi-shopping-cart",
          to: "/orders",
        },
      ],
    },
  ];

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive":
      staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active":
      overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });

  return (
    <>
      {sessionStorage.getItem("token") ? (
        <div className={wrapperClass} onClick={onWrapperClick}>
          <AppTopbar
            onToggleMenuClick={onToggleMenuClick}
            layoutColorMode={layoutColorMode}
            mobileTopbarMenuActive={mobileTopbarMenuActive}
            onMobileTopbarMenuClick={onMobileTopbarMenuClick}
            onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
          />

          <div className="layout-sidebar" onClick={onSidebarClick}>
            <AppMenu
              model={menu}
              onMenuItemClick={onMenuItemClick}
              layoutColorMode={layoutColorMode}
            />
          </div>

          <div className="layout-main-container">
            <div className="layout-main">
              <Switch>
                <Route path="/dashboard" exact component={Dashboard} />
                <Route path="/addbrands" component={AddBrandsFormLayout} />
                <Route path="/managebrands" component={BrandsTable} />
                <Route path="/addcategory" component={CategoryFormLayout} />
                <Route path="/managecategory" component={CategoryTable} />
                <Route path="/addbanner" component={BannerFormLayout} />
                <Route path="/managebanner" component={BannerTable} />
                <Route path="/adddeal" component={DealsFormLayout} />
                <Route path="/managedeals" component={DealsTable} />
                <Route path="/additem" component={ItemFormLayout} />
                <Route path="/crud" component={Crud} />
                <Route path="/manageusers" component={UserTable} />
                <Route path="/transactions" component={TransactionsTable} />
                <Route path="/orders" component={OrdersTable} />
              </Switch>
            </div>
            <AppFooter layoutColorMode={layoutColorMode} />
          </div>
          <CSSTransition
            classNames="layout-mask"
            timeout={{ enter: 200, exit: 200 }}
            in={mobileMenuActive}
            unmountOnExit
          >
            <div className="layout-mask p-component-overlay"></div>
          </CSSTransition>
        </div>
      ) : (
        <div>
          <LoginPageComponent />
        </div>
      )}
    </>
  );
};

export default App;
