import React,{useState,useEffect,useRef} from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from 'primereact/toast';
import { Dialog } from "primereact/dialog";
import { Divider } from 'primereact/divider';
import { OrderService } from '../service/OrderService';
import { Dropdown } from "primereact/dropdown";
import { Calendar } from 'primereact/calendar';
import { ProgressBar } from 'primereact/progressbar';
import { InputText } from "primereact/inputtext";
import configData from "../config.json";
export const TransactionsTable = () => {
    const toast = useRef(null);
    const [orderDetails, setOrderDetails] = useState([]);
    const [currentOrderDetails,setCurrentOrderDetails] = useState(null)
    const [openModal,setOpenModal] = useState(false)
    useEffect(() => {
        const orderService = new OrderService();
        const config = {
            method: 'get',
            url: configData.SERVER_URL+'admin/trans?limit=20&offset=0&orderStatus=Cancelled',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        orderService.getOrders(config).then(data => {setOrderDetails(data.data);});
    }, []);

    const [selectedStatusF, setSelectedStatusF] = useState(null);
    const dt = useRef(null);
    const statusesF = [
        'Success', 'Pending'
    ];
    const statusItemTemplate = (option) => {
        return <span style={{fontSize:"14px",fontWeight:600,textTransform:'capitalize'}}>{option}</span>;
    }
    const onStatusChange = (e) => {
        dt.current.filter(e.value, 'paymentStatus', 'equals');
        setSelectedStatusF(e.value);
    }
    const statusFilter = <Dropdown value={selectedStatusF} options={statusesF} onChange={onStatusChange} itemTemplate={statusItemTemplate} placeholder="Select a Status" showClear style={{width:"110px",fontSize:'10px'}}/>;
    const [globalFilter, setGlobalFilter] = useState('');
    const [selectedDate, setSelectedDate] = useState([new Date(new Date().getFullYear(), new Date().getMonth(), 1),new Date()]);


    const [selectedPTypeF, setSelectedPTypeF] = useState(null);
    const typesP = [
        'CARD', 'COD'
    ];
    const ptypeItemTemplate = (option) => {
        return <span style={{fontSize:"14px",fontWeight:600,textTransform:'capitalize'}}>{option}</span>;
    }
    const onPTypeChange = (e) => {
        dt.current.filter(e.value, 'paymentType', 'equals');
        setSelectedPTypeF(e.value);
    }
    const ptypeFilter = <Dropdown value={selectedPTypeF} options={typesP} onChange={onPTypeChange} itemTemplate={ptypeItemTemplate} placeholder="Select a Type" showClear style={{width:"110px",fontSize:'10px'}}/>;

    const formatDate = (date) => {
        let month = date.getMonth() + 1;
        let day = date.getDate();

        if (month < 10) {
            month = '0' + month;
        }

        if (day < 10) {
            day = '0' + day;
        }

        return date.getFullYear() + '-' + month + '-' + day;
    }
    const [fs,setFs] = useState("")
    const codeFilter = (value, filter) => {
        var date1 = new Date(value);
        var date2 = new Date(filter.split(":")[0]);
        var date3= new Date(filter.split(":")[1]);

        return date1.getTime()>date2.getTime() && date1.getTime()< date3.getTime();
    }
    const onDateChangeUseeffect = (e) => {
            let dat2 = formatDate(e[0])
            let dat1 = formatDate(e[1])
            dt.current.filter(dat2+":"+dat1, 'orderplaced.at', 'custom');

    }
    const onDateChange = (e) => {
        if (e.value[1]!==null){
            let dat2 = formatDate(e.value[1])
            dt.current.filter(fs+":"+dat2, 'orderplaced.at', 'custom');
            setSelectedDate(e.value);
            return
        }
        if (e.value[0]!==null && e.value[1]===null){
            let dat2 = formatDate(e.value[0])
            setFs(dat2)
            setSelectedDate(e.value);
            return
        }
    }
    const reset = () => {
        setSelectedStatusF(null);
        setSelectedDate(undefined);
        setGlobalFilter('');
        dt.current.reset();
    }
    const header = (
        <div className="table-header">
            <Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={reset} />
            <span className="p-input-icon-left" style={{float: 'right'}}>
            
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Search"  style={{marginRight:"15px"}}/>
                <Calendar readOnlyInput value={selectedDate} onChange={onDateChange} dateFormat="yy-mm-dd" showIcon  selectionMode="range" className="p-column-filter" placeholder="Select Date" style={{marginRight:"5px",width:"257px",marginBottom:"5px",marginTop:"5px",marginLeft:"auto",fontSize:"12px !important"}}/>
            </span>
        </div>
    );
    const dateFilter = <Calendar readOnlyInput value={selectedDate} onChange={onDateChange} dateFormat="yy-mm-dd" selectionMode="range" className="p-column-filter" placeholder="Select Date" style={{display: 'none'}}/>;

    useEffect(() => {
        if (orderDetails.length===0){
            if (dt !==null && dt.current !=null){
                onDateChangeUseeffect([new Date(new Date().getFullYear(), new Date().getMonth(), 1),new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()+2)])
            }
        }
    }, []);
    return (
        <>
                    {orderDetails && orderDetails.length===0 && <h1 style={{fontSize:'16px'}}>Transactions</h1>}
            {orderDetails && orderDetails.length > 0 && <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                <DataTable ref={dt} value={orderDetails} globalFilter={globalFilter}  header={header} dataKey="_id" className="datatable-responsive" rows={5} paginator>
                        <Column header="Transaction ID" style={{fontSize:"12px"}} 
                                                body={(rowData) => (
                                                    <>
                                                        {rowData && rowData.transactionDetails && rowData.transactionDetails.data && <h1 style={{fontSize:'12px'}}>{rowData && rowData.transactionDetails && rowData.transactionDetails.data && rowData.transactionDetails.data.id}
                                                        </h1>}
                                                        {rowData && rowData.transactionDetails ===undefined && <h1 style={{fontSize:'12px'}}>-
                                                        </h1>}
                                                    </>
                                                )}/>
                                                <Column header="Payment Type" style={{fontSize:"12px"}} 
                                                field="paymentType"
                                                filter filterElement={ptypeFilter}
                                                body={(rowData) => (
                                                    <>
                                                        <h1 style={{fontSize:'12px'}}>{rowData && rowData.paymentType}</h1>
                                                    </>
                                                )}/>
                        <Column header="Amount" sortable style={{fontSize:"12px"}}
                                                                        body={(rowData) => (
                                                                            <>
                                                                                <h1 style={{fontSize:'12px'}}>{rowData && rowData.paymentsDetails && rowData.paymentsDetails.totalCharges}</h1>
                                                                            </>
                                                                        )}
                        />
                        <Column header="Currency" style={{fontSize:"12px"}}
                                                                        body={(rowData) => (
                                                                            <>
                                                                                <h1 style={{fontSize:'12px'}}>{rowData && rowData.paymentsDetails && rowData.paymentsDetails.currency}</h1>
                                                                            </>
                                                                        )}
                        />
                        <Column header="Transaction Reference ID" style={{fontSize:"12px"}}
                                                                        body={(rowData) => (
                                                                            <>
                                                                                <h1 style={{fontSize:'12px'}}>{rowData && "mbe-"+rowData.paymentId}</h1>
                                                                            </>
                                                                        )}
                        />
                        <Column header="Transaction Status" style={{fontSize:"12px"}}
                        filter filterElement={statusFilter}
                        field = 'paymentStatus'
                        body={(rowData) => (
                            <>
                               {rowData && rowData.paymentStatus=='Success' && <h1 className={`product-badge status-instock`} style={{fontSize:'12px'}}>{rowData.paymentStatus}</h1>}
                               {rowData && rowData.paymentStatus=='Pending' && <h1 className={`product-badge status-lowstock`} style={{fontSize:'12px'}}>{rowData.paymentStatus}</h1>}
                               {rowData && rowData.paymentStatus=='Refund' && <h1 className={`product-badge status-outofstock`} style={{fontSize:'12px'}}>{rowData.paymentStatus}</h1>}
                            </>
                        )}/>
                        <Column header="Date" style={{fontSize:"12px"}}
                        field = 'orderplaced.at'
                        filter filterElement={dateFilter} filterFunction={codeFilter}
                        body={(rowData) => (
                            <>
                                <h1 style={{fontSize:'12px'}}>{rowData && rowData.orderplaced && rowData.orderplaced.at.split("T")[0]}</h1>
                            </>
                        )}/>
                        <Column
                            header="View"
                            style={{fontSize:"12px"}}
                            body={(rowData) => (
                                <>
                                    <Button icon="pi pi-search" type="button" className="p-button-text" onClick={() => {setCurrentOrderDetails(rowData);setOpenModal(true)}}/>
                                </>
                            )}
                        />
                    </DataTable>
                </div>
            </div>
        </div>}
        {orderDetails && orderDetails.length===0 && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
        <Dialog header="Transaction Details" visible={openModal} style={{ width: '50vw' }} onHide={() => setOpenModal(false)} modal>
                        <h1 style={{fontSize:"14px",color:"black"}}>User Details</h1>
                        <Divider /> 
                        {currentOrderDetails && currentOrderDetails.userDetails && currentOrderDetails.userDetails.name && <h1 style={{fontSize:"12px",color:"#898080"}}>Name: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{currentOrderDetails.userDetails.name}</span></h1>}
                        {currentOrderDetails && currentOrderDetails.userDetails && currentOrderDetails.userDetails.email && <h1 style={{fontSize:"12px",color:"#898080"}}>Email: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{currentOrderDetails.userDetails.email}</span></h1>}
                        <Divider /> 
                        { currentOrderDetails && currentOrderDetails.paymentType !=='COD' && currentOrderDetails.paymentStatus !=='Pending' && <><h1 style={{fontSize:"14px",color:"black"}}>Card Details</h1>
                        <Divider/>
                        {currentOrderDetails && currentOrderDetails.transactionDetails && currentOrderDetails.transactionDetails.data && currentOrderDetails.transactionDetails.data.card && <h1 style={{fontSize:"12px",color:"#898080"}}>Country: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{currentOrderDetails.transactionDetails.data.card.country}</span></h1>}
                        {currentOrderDetails && currentOrderDetails.transactionDetails && currentOrderDetails.transactionDetails.data && currentOrderDetails.transactionDetails.data.card && <h1 style={{fontSize:"12px",color:"#898080"}}>Expiry: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{currentOrderDetails.transactionDetails.data.card.expiry}</span></h1>}
                        {currentOrderDetails && currentOrderDetails.transactionDetails && currentOrderDetails.transactionDetails.data && currentOrderDetails.transactionDetails.data.card && <h1 style={{fontSize:"12px",color:"#898080"}}>First 6 Digits: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{currentOrderDetails.transactionDetails.data.card.first_6digits}</span></h1>}
                        {currentOrderDetails && currentOrderDetails.transactionDetails && currentOrderDetails.transactionDetails.data && currentOrderDetails.transactionDetails.data.card && <h1 style={{fontSize:"12px",color:"#898080"}}>Issuer: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{currentOrderDetails.transactionDetails.data.card.issuer}</span></h1>}
                        {currentOrderDetails && currentOrderDetails.transactionDetails && currentOrderDetails.transactionDetails.data && currentOrderDetails.transactionDetails.data.card && <h1 style={{fontSize:"12px",color:"#898080"}}>Last 4 Digits: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{currentOrderDetails.transactionDetails.data.card.last_4digits}</span></h1>}
                        {currentOrderDetails && currentOrderDetails.transactionDetails && currentOrderDetails.transactionDetails.data && currentOrderDetails.transactionDetails.data.card && <h1 style={{fontSize:"12px",color:"#898080"}}>Type: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{currentOrderDetails.transactionDetails.data.card.type}</span></h1>}
                        </>}
                </Dialog>
        </>
    );
};
