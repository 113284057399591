import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import "./customButton.css"
import configData from "../config.json";
export const ItemFormLayout = () => {

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Add Item</h5>
                    <div className="p-fluid formgrid grid">
                        <div className="field col-12">
                            <label htmlFor="itemName">Name</label>
                            <InputText id="itemName" type="text" />
                        </div>
                        <div className="field col-12">
                            <label htmlFor="brand">Brand</label>
                            <InputText id="brand" type="text" />
                        </div>
                        <div className="field col-12">
                            <label htmlFor="category">Category</label>
                            <InputText id="category" type="text" />
                        </div>
                        <div className="field col-12">
                            <label htmlFor="price">Price</label>
                            <InputText id="price" type="text" />
                        </div>
                        <div className="field col-12">
                            <label htmlFor="deal">Deal</label>
                            <InputText id="deal" type="text" />
                        </div>
                        <div className="field col-12">
                            <label htmlFor="discount">Discount</label>
                            <InputText id="discount" type="text" />
                        </div>
                        <div className="field col-12">
                            <label htmlFor="discount">Description</label>
                            <InputText id="discount" type="text" />
                        </div>
                        <div className="field col-12">
                            <label htmlFor="discount">Key Features</label>
                            <InputText id="discount" type="text" />
                        </div>
                        <div className="field col-12">
                            <label htmlFor="discount">Specifications</label>
                            <InputText id="discount" type="text" />
                        </div>
                        <div className="field col-12">
                        <label htmlFor="brandImage">Item images</label>
                        <FileUpload name="demo[]" url="./upload.php" multiple accept="image/*" maxFileSize={1000000} />
                        </div>

                        <div className="field col-12">
                        <Button className="p-button-rounded formButton">Add Item</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
