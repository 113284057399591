import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import "./customButton.css";
import { CategoryService } from "../service/CategoryService";
import { Toast } from "primereact/toast";
import configData from "../config.json";
import S3 from "react-aws-s3";
import { CONFIG } from "../utils/constants";

export const CategoryFormLayout = () => {
  const fileInput = useRef();
  const toast = useRef(null);
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const history = useHistory();

  const onTemplateUpload = (e) => {
    onUpload(e.files);
  };

  const onUpload = (event) => {
    let file = fileInput.current.files[0];
    let newFileName = fileInput.current.files[0].name.replace(/\..+$/, "");
    const config = CONFIG;
    const ReactS3Client = new S3(config);
    ReactS3Client.uploadFile(file, newFileName).then((data) => {
      if (data.status === 204) {
        setImage(data.location);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Image uploaded successfully",
          life: 3000,
        });
      } else {
        console.log("fail");
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while upload image",
          life: 3000,
        });
      }
    });
  };

  const onSubmit = () => {
    const categoryService = new CategoryService();

    const category = {
      name: name,
      images: image,
    };
    const config = {
      method: "post",
      url: configData.SERVER_URL + "admin/addCategory",
      headers: {
        "Content-Type": "application/json",
      },
      data: category,
    };
    categoryService.addCategory(config).then((data) => {
      if (data.status === "Success") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Category added successfully",
          life: 3000,
        });
        history.push("/managecategory");
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while adding a new category",
          life: 3000,
        });
      }
    });
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12">
          <div className="card">
            <h5>Add Category</h5>
            <div className="p-fluid formgrid grid">
              <div className="field col-12">
                <label htmlFor="categoryName">Category name</label>
                <InputText
                  id="categoryName"
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="field col-12">
                <label htmlFor="categoryImage">Category image</label>
                <FileUpload
                  ref={fileInput}
                  name="demo[]"
                  customUpload={true}
                  uploadHandler={onTemplateUpload}
                  onUpload={onTemplateUpload}
                  accept="image/*"
                  maxFileSize={1000000}
                  emptyTemplate={
                    <p className="p-m-3" style={{ fontSize: "15px" }}>
                      Drag and drop files to here to upload.
                    </p>
                  }
                  auto
                />
              </div>
              <div className="field col-12">
                <Button
                  className="p-button-rounded formButton1"
                  onClick={onSubmit}
                >
                  Add category
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
