import React,{useState,useEffect,useRef} from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { UserService } from '../service/UsersService';
import { Dialog } from "primereact/dialog";
import { Toast } from 'primereact/toast';
import { Sidebar } from 'primereact/sidebar';
import { Divider } from 'primereact/divider';
import { OrderService } from '../service/OrderService';
import configData from "../config.json";
export const UserTable = () => {
    let emptyUser = {
        name: ""
    };
    const [users, setUsers] = useState(null);
    const [visibleRight, setVisibleRight] = useState(false);
    const toast = useRef(null);
    const [deleteUserDialog, setDeleteUserDialog] = useState(false);
    const [relistUserDialog, setRelistUserDialog] = useState(false);
    const [userDetails, setUserDetails] = useState(emptyUser);
    const [uid,setUid] = useState("")
    useEffect(() => {
        const userService = new UserService();
        const config = {
            method: 'get',
            url: configData.SERVER_URL+'admin/profile',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        userService.getUsers(config).then(data => setUsers(data));
        
    }, []);
    useEffect(() => {
        const userService = new UserService();
        const config = {
            method: 'get',
            url: configData.SERVER_URL+'admin/profile',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        userService.getUsers(config).then(data => setUsers(data));

    }, [deleteUserDialog,relistUserDialog]);
    const hideDeleteUserDialog = () => {
        setDeleteUserDialog(false);
    };
    const hideRelistUserDialog = () => {
        setRelistUserDialog(false);
    };
    const [orderDetails, setOrderDetails] = useState(null);
    const [recentorderDetails, setRecentOrderDetails] = useState(null);
    const getOrders = (uid)=>{
            const orderService = new OrderService();
            const config = {
                method: 'get',
                url: configData.SERVER_URL+'home/orders?limit=20&offset=0&userid='+uid,
                headers: {
                    'Content-Type' : 'application/json'
                }
            }
            orderService.getOrders(config).then(data => {setOrderDetails(data.data);setRecentOrderDetails(data.data[0])});
    }
    const deleteUser = () => {
        const userService = new UserService();
        const config = {
            method: "put",
            url: configData.SERVER_URL+"home/user/profile/"+uid,
            headers: {
                "Content-Type": "application/json",
            },
            data: { status: "Active" },
        };
        userService.deleteUser(config).then((data) => {
            if (data) {
                toast.current.show({ severity: "success", summary: "Success", detail: "Account deactivated successfully", life: 3000 });
                setDeleteUserDialog(false);
                setUserDetails(emptyUser);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error while deactivating account", life: 3000 });
            }
        });
    };

    const relistUser = () => {
        const userService = new UserService();
        const config = {
            method: "put",
            url: configData.SERVER_URL+"home/user/profile/"+uid,
            headers: {
                "Content-Type": "application/json",
            },
            data: { status: "Not Active" },
        };
        userService.relistUser(config).then((data) => {
            if (data) {
                toast.current.show({ severity: "success", summary: "Success", detail: "Account activated successfully", life: 3000 });
                setRelistUserDialog(false);
                setUserDetails(emptyUser);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error while reactivating account", life: 3000 });
            }
        });
    };
    const deleteUserDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUserDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteUser} />
        </>
    );
    const relistUserDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideRelistUserDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={relistUser} />
        </>
    );
    const activestatusBodyTemplate = (rowData) => {
        if (rowData.userDetails.status == "Active") {
            return (
                <>
                    <span className={`product-badge status-instock`}>{rowData.userDetails.status}</span>
                </>
            );
        } else {
            return (
                <>
                    <span className={`product-badge status-outofstock`}>Not Active</span>
                </>
            );
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {rowData.userDetails.status =="Active" && <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => {setUserDetails(rowData.userDetails);setUid(rowData.userDetails["_id"]);setDeleteUserDialog(true);}} />}
                {rowData.userDetails.status =="Not Active" && <Button icon="pi pi-list" className="p-button-rounded p-button-info" onClick={() => {setUserDetails(rowData.userDetails);setUid(rowData.userDetails["_id"]);setRelistUserDialog(true);}} />}

            </div>
        );
    };
    return (
        <>
                        <Sidebar visible={visibleRight} position="right" onHide={() => setVisibleRight(false)} style={{width:'420px'}}>
                        {userDetails && userDetails['userDetails'] && userDetails['userDetails'].profilePhoto && <img src={userDetails['userDetails'].profilePhoto} alt={userDetails['userDetails'].profilePhoto} width="100" className="mt-0 mx-auto mb-5 block shadow-2" />}
                        <h1 style={{fontSize:"14px",color:"black"}}>Personal details</h1>
                        <Divider /> 
                        {userDetails && userDetails['userDetails'] && userDetails['userDetails'].name && <h1 style={{fontSize:"12px",color:"#898080"}}>Name: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{userDetails['userDetails'].name}</span></h1>}
                        {userDetails && userDetails['userDetails'] && userDetails['userDetails'].email && <h1 style={{fontSize:"12px",color:"#898080"}}>Email: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{userDetails['userDetails'].email}</span></h1>}
                        {userDetails && userDetails['userDetails'] && userDetails['userDetails'].gender && <h1 style={{fontSize:"12px",color:"#898080"}}>Gender: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{userDetails['userDetails'].gender}</span></h1>}
                        {userDetails && userDetails['userDetails'] && userDetails['userDetails'].dob && <h1 style={{fontSize:"12px",color:"#898080"}}>Date of birth: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{userDetails['userDetails'].dob}</span></h1>}
                        <Divider /> 
                        <h1 style={{fontSize:"14px",color:"black"}}>Address</h1>
                        <Divider/>
                        {userDetails && userDetails['address'] && userDetails['address'].addressLine1 && <h1 style={{fontSize:"12px",color:"#898080"}}>address line 1: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{userDetails['address'].addressLine1}</span></h1>}
                        {userDetails && userDetails['address'] && userDetails['address'].addressLine2 && <h1 style={{fontSize:"12px",color:"#898080"}}>address line 2: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{userDetails['address'].addressLine2}</span></h1>}
                        {userDetails && userDetails['address'] && userDetails['address'].city && <h1 style={{fontSize:"12px",color:"#898080"}}>city: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{userDetails['address'].city}</span></h1>}
                        {userDetails && userDetails['address'] && userDetails['address'].state && <h1 style={{fontSize:"12px",color:"#898080"}}>state: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{userDetails['address'].state}</span></h1>}
                        {userDetails && userDetails['address'] && userDetails['address'].country && <h1 style={{fontSize:"12px",color:"#898080"}}>country: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{userDetails['address'].country}</span></h1>}
                        {userDetails && userDetails['address'] && userDetails['address'].postalCode && <h1 style={{fontSize:"12px",color:"#898080"}}>pin code: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{userDetails['address'].postalCode}</span></h1>}
                        <Divider /> 
                        <h1 style={{fontSize:"14px",color:"black"}}>Recent Order</h1>
                        <Divider/>
                        {recentorderDetails && <h1 style={{fontSize:"12px",color:"#898080"}}>Order Id: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{recentorderDetails._id}</span></h1>}
                        {recentorderDetails && <h1 style={{fontSize:"12px",color:"#898080"}}>Payment Status: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{recentorderDetails.paymentStatus}</span></h1>}
                        {recentorderDetails && <h1 style={{fontSize:"12px",color:"#898080"}}>Payment Type: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{recentorderDetails.paymentType}</span></h1>}
                        {recentorderDetails && <h1 style={{fontSize:"12px",color:"#898080"}}>Order Status: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{recentorderDetails.orderStatus}</span></h1>}
                        {recentorderDetails && <h1 style={{fontSize:"12px",color:"#898080"}}>Items Ordered:</h1>}
                        {recentorderDetails && recentorderDetails.ItemsOrdered && recentorderDetails.ItemsOrdered.items && <DataTable
                            value={recentorderDetails.ItemsOrdered.items} 
                        >
                        <Column field="itemName" header="Name" sortable style={{ fontSize: "12px" }}></Column>
                        <Column field="itemPrice" header="Price" style={{ fontSize: "12px" }}></Column>
                        <Column field="itemCategory" header="Category" style={{ fontSize: "12px" }}></Column>
                        <Column field="itemBrand" header="Brand" style={{ fontSize: "12px" }}></Column>
                        <Column field="quantity" header="Availability" style={{ fontSize: "12px" }}></Column>
                        <Column field="itemDeal" header="Deal" style={{ fontSize: "12px" }}></Column>
                        </DataTable>}
                        <Divider/>
                        <h1 style={{fontSize:"14px",color:"black"}}>Orders</h1>
                        <Divider/>
                        <DataTable value={orderDetails} className="datatable-responsive" rows={5} paginator>
                        <Column field="_id" header="Order ID" style={{fontSize:"10px"}} 
                        body={(rowData) => (
                            <>
                                <span style={{fontSize:'10px'}}>{rowData && rowData._id && rowData._id.substring(0,10)}</span>
                            </>
                        )}/>
                        <Column field="orderStatus" header="Order Status"  style={{fontSize:"10px"}}/>
                        <Column field="orderplaced.at" header="Order Placed on"  style={{fontSize:"10px"}} 
                                                    body={(rowData) => (
                                                        <>
                                                            <span style={{fontSize:'10px'}}>{rowData && rowData.orderplaced && rowData.orderplaced.at && rowData.orderplaced.at.split("T")[0]}</span>
                                                        </>
                                                    )}/>
                        <Column field="paymentsDetails.totalCharges" header="Order Amount"  style={{fontSize:"10px"}}/>
                        <Column field="orderdelivered.at" header="Date"  style={{fontSize:"10px"}}
                                                    body={(rowData) => (
                                                        <>
                                                            <span style={{fontSize:'10px'}}>{rowData && rowData.orderdelivered && rowData.orderdelivered.at?rowData.orderdelivered.at.split("T")[0]:"Not Delivered"}</span>
                                                        </>
                                                    )}/>
                        <Column field="orderShipped.at" header="Shipping Status"  style={{fontSize:"10px"}}
                                                    body={(rowData) => (
                                                        <>
                                                            <span style={{fontSize:'10px'}}>{rowData && rowData.orderShipped && rowData.orderShipped.at?rowData.orderShipped.at.split("T")[0]:"Not Shipped"}</span>
                                                        </>
                                                    )}/>
                    </DataTable>
                </Sidebar>
                <Toast ref={toast} />
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Users</h5>
                    <DataTable value={users} className="datatable-responsive" rows={5} paginator>
                        <Column header="Profile photo" style={{fontSize:"12px"}} body={(data) => <img src={data.userDetails.profilePhoto} alt={data.userDetails.profilePhoto} width="50" />} />
                        <Column field="userDetails.name" header="Name"  style={{fontSize:"12px"}}/>
                        <Column field="userDetails.email" header="Email"  style={{fontSize:"12px"}}/>
                        <Column field="userDetails.gender" header="Gender"  style={{fontSize:"12px"}}/>
                        <Column field="userDetails.dob" header="Dob"  style={{fontSize:"12px"}}/>
                        <Column field="userDetails.status" header="Status"  body={activestatusBodyTemplate} style={{fontSize:"12px"}}/>
                        <Column
                            header="View"
                            style={{fontSize:"12px"}}
                            body={(rowData) => (
                                <>
                                    <Button icon="pi pi-search" type="button" className="p-button-text" onClick={() => {setVisibleRight(true);setUserDetails(rowData);getOrders(rowData.userDetails._id)}}/>
                                </>
                            )}
                        />
                        <Column body={actionBodyTemplate} style={{fontSize:"12px"}}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
        <Dialog visible={deleteUserDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteUserDialogFooter} onHide={hideDeleteUserDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {userDetails && (
                        <span>
                            Are you sure you want to deactivate <b>{userDetails.name}'s</b> account ?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={relistUserDialog} style={{ width: "450px" }} header="Confirm" modal footer={relistUserDialogFooter} onHide={hideRelistUserDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {userDetails && (
                        <span>
                            Are you sure you want to activate <b>{userDetails.name}'s</b> account ?
                        </span>
                    )}
                </div>
            </Dialog>
        </>
    );
};
