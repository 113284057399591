export const lineData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'Mobiles',
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: '#2f4860',
            borderColor: '#2f4860',
            tension: .4
        },
        {
            label: 'Televisons',
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: '#00bb7e',
            borderColor: '#00bb7e',
            tension: .4
        },
        {
            label: 'Refrigerators',
            data: [18, 28, 60, 9, 36, 97, 70],
            fill: false,
            backgroundColor: '#439bf2',
            borderColor: '#439bf2',
            tension: .4
        },
        {
            label: 'Speakers',
            data: [8, 8, 6, 69, 16, 27, 30],
            fill: false,
            backgroundColor: '#9050b2',
            borderColor: '#9050b2',
            tension: .4
        }
    ]
};