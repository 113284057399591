import React, { useState, useEffect, useRef } from "react";
import S3 from "react-aws-s3";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import "./customButton.css";
import { PreSignedUrlService } from "../service/PreSignedUrlService";
import { BannerService } from "../service/BannerService";
import { Toast } from "primereact/toast";
import configData from "../config.json";
import { CONFIG } from "../utils/constants";
export const BannerFormLayout = () => {
  const [name, setName] = useState("");
  const toast = useRef(null);
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [text, setText] = useState("");
  const [selectedBannerType, setSelectedBannerType] = useState(null);
  const fileInput = useRef();
  const bannertype = [
    { name: "1" },
    { name: "2" },
    { name: "3" },
    { name: "4" },
    { name: "5" },
    { name: "6" },
  ];
  const onTemplateUpload = (e) => {
    onUpload(e.files);
  };
  const onBannerTypeChange = (e) => {
    setSelectedBannerType(e.value);
  };
  const onUpload = (event) => {
    let file = fileInput.current.files[0];
    let newFileName = fileInput.current.files[0].name.replace(/\..+$/, "");
    console.log("file", file, "newfileName", newFileName);
    const config = CONFIG;
    const ReactS3Client = new S3(config);
    ReactS3Client.uploadFile(file, newFileName).then((data) => {
      if (data.status === 204) {
        setImage(data.location);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Image uploaded successfully",
          life: 3000,
        });
      } else {
        console.log("fail");
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while upload image",
          life: 3000,
        });
      }
    });
  };
  // const onUpload = (e) => {
  //     const data = {
  //         "fileName":e[0].name,
  //         "fileType":e[0].type.split("/")[1],
  //     }
  //     const objectUrl = e[0]
  //     const type = e[0].type
  //     const config = {
  //         method: 'post',
  //         url: configData.SERVER_URL+"home/util/presignedurl",
  //         headers: {
  //             'Content-Type' : 'application/json'
  //         },
  //         data: data
  //     }
  //     const preSignedUrlService = new PreSignedUrlService();
  //     preSignedUrlService.generatePreSignedUrl(config).then(data => {if (data.url !== "") {
  //         setImage(data.url);
  //         preSignedUrlService.uploadOnGCP(data.url,objectUrl,type ).then(resp =>{
  //             toast.current.show({severity:'success', summary: 'Success', detail:'Image uploaded successfully', life: 3000});
  //         }).catch((error)=>{
  //             toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
  //         })
  //     }else{
  //         toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
  //     }});
  // }
  const onSubmit = () => {
    const bannerService = new BannerService();
    const banner = {
      name: name,
      images: image,
      description: description,
      text: text,
      bannertype: selectedBannerType ? selectedBannerType.name : "",
    };
    const config = {
      method: "post",
      url: configData.SERVER_URL + "admin/banners",
      headers: {
        "Content-Type": "application/json",
      },
      data: banner,
    };
    bannerService.addBanner(config).then((data) => {
      if (data.status === "Success") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Banner added successfully",
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while adding a new banner",
          life: 3000,
        });
      }
    });
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12">
          <div className="card">
            <h5>Add Banner</h5>
            <div className="p-fluid formgrid grid">
              <div className="field col-12">
                <label htmlFor="bannerName">Banner name</label>
                <InputText
                  id="bannerName"
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="field col-12">
                <label htmlFor="bannerDesc">Banner description</label>
                <InputText
                  id="bannerDesc"
                  type="text"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="field col-12">
                <label htmlFor="bannerText">Banner text</label>
                <InputText
                  id="bannerText"
                  type="text"
                  onChange={(e) => setText(e.target.value)}
                />
              </div>
              <div className="field col-12">
                <label htmlFor="bannerText">Banner type</label>
                <Dropdown
                  value={selectedBannerType}
                  options={bannertype}
                  onChange={onBannerTypeChange}
                  optionLabel="name"
                  placeholder="Select a Banner Type"
                />
              </div>
              <div className="field col-12">
                <label htmlFor="bannerImage">Banner image</label>
                <FileUpload
                  name="demo[]"
                  ref={fileInput}
                  customUpload={true}
                  uploadHandler={onTemplateUpload}
                  onUpload={onTemplateUpload}
                  accept="image/*"
                  maxFileSize={1000000}
                  emptyTemplate={
                    <p className="p-m-3" style={{ fontSize: "15px" }}>
                      Drag and drop files to here to upload.
                    </p>
                  }
                />
              </div>
              <div className="field col-12">
                <Button
                  className="p-button-rounded formButton"
                  onClick={onSubmit}
                >
                  Add banner
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
